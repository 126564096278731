import React, { createContext, useContext, useMemo } from 'react'
import Axios, { AxiosInstance } from 'axios'
import { IAuthTokens, TokenRefreshRequest, applyAuthTokenInterceptor } from 'axios-jwt'

export const BASE_URL = process.env.REACT_APP_BASE_API_URL

export const AxiosContext = createContext<AxiosInstance | undefined>(undefined)

export default function AxiosProvider ({
  children
}: React.PropsWithChildren<unknown>): JSX.Element {
  const axios = useMemo(() => {
    const axiosInstance = Axios.create({
      baseURL: BASE_URL,
      headers: {
        'Content-Type': 'application/json'
      }
    })

    const requestRefresh: TokenRefreshRequest = async (refreshToken: string): Promise<IAuthTokens | string> => {
      console.warn('requestRefresh')
      const response = await Axios.post(`${BASE_URL}/auth/refresh`,
        {}, {
          headers: {
            Authorization: `Bearer ${refreshToken}`
          }
        })
      console.log(response.data)
      return response.data.access_token
    }

    applyAuthTokenInterceptor(axiosInstance, { requestRefresh })

    return axiosInstance
  }, [])

  return (
    <AxiosContext.Provider value={axios}> {children} </AxiosContext.Provider>
  )
}

export function useAxios (): AxiosInstance {
  const context = useContext(AxiosContext)
  if (context !== undefined) {
    return context
  }
  throw Error('useAxios must be called within axios provider!')
}
