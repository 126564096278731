import { IConsumption, InjectionMethod } from '../interfaces/drugs'

export function transformCalculationResults (
  consumptionData: IConsumption[],
  groupByMethod: boolean,
  atcGroupLevel: number
): IConsumption[] {
  /// Group the drugs by method and atc group level.
  /// It will return the new consumption objects with virtual drugs.
  const groupedData: IConsumption[] = []

  if (!groupByMethod && atcGroupLevel === 5) {
    return consumptionData
  }

  if (groupByMethod && atcGroupLevel === 5) {
    // Group every drug with the same atc and sum up the consumption
    for (let i = 0; i < consumptionData.length; i++) {
      const drug = consumptionData[i]
      let methodGroup = groupedData.find(group => group.atc === drug.atc)
      if (methodGroup === undefined) {
        methodGroup = {
          atc: drug.atc,
          method: InjectionMethod.Combined,
          name: drug.name,
          standardMethodologyResult: drug.standardMethodologyResult,
          childrenMethodologyResult: drug.childrenMethodologyResult
        }
        groupedData.push(methodGroup)
      } else {
        methodGroup.standardMethodologyResult = methodGroup.standardMethodologyResult.plus(drug.standardMethodologyResult)
        methodGroup.childrenMethodologyResult = methodGroup.childrenMethodologyResult.plus(drug.childrenMethodologyResult)
      }
    }
  } else {
    let atcSplice = 7
    switch (atcGroupLevel) {
      case 2:
        atcSplice = 3
        break
      case 3:
        atcSplice = 4
        break
      case 4:
        atcSplice = 5
        break
      default:
        atcSplice = 7
        break
    }
    for (let i = 0; i < consumptionData.length; i++) {
      const drug = consumptionData[i]
      let methodGroup = groupedData.find(group => group.atc.substring(0, atcSplice) === drug.atc.substring(0, atcSplice))
      if (methodGroup === undefined) {
        methodGroup = {
          atc: drug.atc.substring(0, atcSplice),
          method: atcSplice === 7 ? drug.method : InjectionMethod.Combined,
          name: atcSplice === 7 ? drug.name : '',
          standardMethodologyResult: drug.standardMethodologyResult,
          childrenMethodologyResult: drug.childrenMethodologyResult
        }
        groupedData.push(methodGroup)
      } else {
        methodGroup.standardMethodologyResult = methodGroup.standardMethodologyResult.plus(drug.standardMethodologyResult)
        methodGroup.childrenMethodologyResult = methodGroup.childrenMethodologyResult.plus(drug.childrenMethodologyResult)
      }
    }
  }

  return groupedData
}
