import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material'
import React from 'react'
import { useMutation } from 'react-query'
import { useAxios } from '../providers/AxiosProvider'

export default function ChangePasswordDialog ({ open, onClose }: { open: boolean, onClose: () => void }): JSX.Element {
  const [password, setPassword] = React.useState('')

  const axios = useAxios()

  interface IChangePassword {
    password: string
  }

  const commitCreation = useMutation(
    async (payload: IChangePassword) => await axios.post('/users/change_password', payload), {
      onSuccess: async () => {
        onClose()
      }
    }
  )

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    commitCreation.mutate({
      password
    })
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Сменить пароль</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent dividers>
          <Grid container spacing={2}>
            {commitCreation.isError &&
              <Grid item xs={12}>
                <Alert severity='error' title='Ошибка смены пароля' />
              </Grid>}
            <Grid item xs={12}>
              <TextField
                id='password'
                label='Новый пароль'
                variant='outlined'
                fullWidth
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type={'password'}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type='submit' disabled={commitCreation.isLoading}>
            Сменить пароль
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
