import React, { useState } from 'react'
import { Alert, Box, Button, Card, CardContent, Container, Grid, TextField } from '@mui/material'
import { useMutation } from 'react-query'
import { useAxios } from '../providers/AxiosProvider'
import { setAuthTokens } from 'axios-jwt'

function Login ({ setLoginState }: { setLoginState: () => void }): JSX.Element {
  const client = useAxios()
  const [username, setUserName] = useState('')
  const [password, setPassword] = useState('')

  const loginSubmition = useMutation(async (credentials: { 'username': string, 'password': string }) => {
    const { data } = await client.post('/auth/login', credentials)
    return data
  }, {
    onSuccess: (response) => {
      setAuthTokens({
        accessToken: response.access_token,
        refreshToken: response.refresh_token
      })
      setLoginState()
    }
  })

  const handleSubmit = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault()
    loginSubmition.mutate({
      username,
      password
    })
  }

  return (
    <Container sx={{ mt: 16 }} maxWidth='xs'>
      <Card>
        <CardContent>
          <Box p={4} />
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label='Username'
                      name='username'
                      variant='outlined'
                      onChange={e => setUserName(e.target.value)}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label='Password'
                      name='password'
                      type='password'
                      variant='outlined'
                      required
                      onChange={e => setPassword(e.target.value)}
                    />
                  </Grid>

                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Button
                  color='primary'
                  fullWidth
                  variant='contained'
                  type='submit'
                  disabled={loginSubmition.isLoading}
                >
                  Log in
                </Button>
              </Grid>
              {loginSubmition.isError &&
                <Grid item xs={12}>
                  <Alert severity='error'>Unable to log in with provided credentials.</Alert>
                </Grid>}
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Container>
  )
}

export default Login
