import { AppBar, Box, Button, IconButton, Menu, MenuItem, Toolbar, Tooltip } from '@mui/material'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { isLoggedIn } from 'axios-jwt'
import { HelpOutlineOutlined, LanguageOutlined, PlayArrowOutlined } from '@mui/icons-material'
import HelpScreen from '../screens/HelpScreen'
import AppBarLogo from '../molecules/AppBarLogo'

export default function Layout (props: {
  handleLogOut: () => void
  locale: string
  setLocale: (locale: string) => void
  children: JSX.Element
}): JSX.Element {
  const [showIntroScreen, setShowIntroScreen] = React.useState(false)
  const [localeMenuAnchorEl, setLocaleMenuAnchorEl] = React.useState<null | HTMLElement>(null)
  const localeMenuOpen = Boolean(localeMenuAnchorEl)

  const intl = useIntl()

  const handleLocaleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setLocaleMenuAnchorEl(event.currentTarget)
  }

  const handleLocaleMenuClose = (): void => {
    setLocaleMenuAnchorEl(null)
  }

  const handleLocaleChange = (locale: string): void => {
    props.setLocale(locale)
    handleLocaleMenuClose()
  }

  // Determine if the help screen should be shown
  // by checking localStorage for the key 'helpScreenShown'
  React.useEffect((): void => {
    const helpScreenShown = localStorage.getItem('helpScreenShown')
    if (helpScreenShown === null) {
      setShowIntroScreen(true)
    }
  })

  const handleHelpScreenClose = (): void => {
    setShowIntroScreen(false)
    localStorage.setItem('helpScreenShown', 'true')
  }

  return (
    <Box sx={{ display: 'flex', height: '100%' }}>
      <AppBar>
        <Toolbar>
          <Link to='/' style={{ textDecoration: 'none', flexGrow: 1 }}>
            <AppBarLogo/>
          </Link>
          {showIntroScreen && (
            <Button
              variant='outlined'
              color='inherit'
              onClick={(): void => handleHelpScreenClose()}
              startIcon={<PlayArrowOutlined/>}
            >
              <FormattedMessage id='layout.hideIntroScreen' defaultMessage='Начать работу'/>
            </Button>
          )}
          <div style={
            {
              flexGrow: 1
            }
          }
          />
          <Tooltip title={intl.formatMessage({ id: 'layout.help', defaultMessage: 'Справка' })}>
            <IconButton
              color='inherit'
              onClick={() => showIntroScreen ? handleHelpScreenClose() : setShowIntroScreen(true)}
              sx={{ mr: 1 }}
            >
              <HelpOutlineOutlined/>
            </IconButton>
          </Tooltip>
          <Tooltip title={intl.formatMessage({ id: 'layout.language', defaultMessage: 'Язык' })}>
            <IconButton
              color='inherit'
              onClick={handleLocaleMenuOpen}
            >
              <LanguageOutlined/>
            </IconButton>
          </Tooltip>
          <Menu
            open={localeMenuOpen}
            onClose={handleLocaleMenuClose}
            anchorEl={localeMenuAnchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
          >
            <MenuItem disabled={props.locale === 'ru'} onClick={() => handleLocaleChange('ru')}>Русский</MenuItem>
            <MenuItem disabled={props.locale === 'en'} onClick={() => handleLocaleChange('en')}>English</MenuItem>
          </Menu>
          {isLoggedIn() &&
            <Button color='inherit' variant='text' onClick={() => props.handleLogOut()}>
              <FormattedMessage id='layout.logout' defaultMessage='Выйти'/>
            </Button>}

        </Toolbar>
      </AppBar>
      <Box sx={{
        flexGrow: 1,
        paddingTop: 12,
        height: '100%',
        overflow: 'auto'
      }}
      >
        {showIntroScreen ? <HelpScreen/> : props.children}
      </Box>
    </Box>
  )
}
