import { Decimal } from 'decimal.js'
import { IDrug, IAgeGroups, IConsumption } from '../interfaces/drugs'

interface IAgeFractions {
  children: Decimal[]
  age_1: Decimal[]
  age_2: Decimal[]
  age_3: Decimal[]
  age_4: Decimal[]
  age_5: Decimal[]
  age_6: Decimal[]
  age_7: Decimal[]
  age_8: Decimal[]
  age_9: Decimal[]
  age_10: Decimal[]
  age_11: Decimal[]
  age_12: Decimal[]
  adults: Decimal[]
}

interface IAgeSums {
  children: Decimal
  age_1: Decimal
  age_2: Decimal
  age_3: Decimal
  age_4: Decimal
  age_5: Decimal
  age_6: Decimal
  age_7: Decimal
  age_8: Decimal
  age_9: Decimal
  age_10: Decimal
  age_11: Decimal
  age_12: Decimal
  adults: Decimal
}

interface IParsedDrugDDD {
  ddd_child: Decimal
  ddd_1: Decimal
  ddd_2: Decimal
  ddd_3: Decimal
  ddd_4: Decimal
  ddd_5: Decimal
  ddd_6: Decimal
  ddd_7: Decimal
  ddd_8: Decimal
  ddd_9: Decimal
  ddd_10: Decimal
  ddd_11: Decimal
  ddd_12: Decimal
  ddd_adult: Decimal
  minimal_allowed_age: number
}

function sumAll (args: Decimal[]): Decimal {
  return args.reduce((acc, curr) => acc.plus(curr), new Decimal(0))
}

function calculateFractions (age: Decimal, ageSums: IAgeSums, ageIndex: number): Decimal[] {
  return [
    age.div(ageSums.children),
    ageIndex <= 0 ? new Decimal(0) : age.div(ageSums.age_1),
    ageIndex <= 1 ? new Decimal(0) : age.div(ageSums.age_2),
    ageIndex <= 2 ? new Decimal(0) : age.div(ageSums.age_3),
    ageIndex <= 3 ? new Decimal(0) : age.div(ageSums.age_4),
    ageIndex <= 4 ? new Decimal(0) : age.div(ageSums.age_5),
    ageIndex <= 5 ? new Decimal(0) : age.div(ageSums.age_6),
    ageIndex <= 6 ? new Decimal(0) : age.div(ageSums.age_7),
    ageIndex <= 7 ? new Decimal(0) : age.div(ageSums.age_8),
    ageIndex <= 8 ? new Decimal(0) : age.div(ageSums.age_9),
    ageIndex <= 9 ? new Decimal(0) : age.div(ageSums.age_10),
    ageIndex <= 10 ? new Decimal(0) : age.div(ageSums.age_11),
    ageIndex <= 11 ? new Decimal(0) : age.div(ageSums.age_12),
    ageIndex <= 12 ? new Decimal(0) : age.div(ageSums.adults)
  ]
}

function parseDrugData (drug: IDrug): IParsedDrugDDD {
  // Convert every ddd value to decimal
  // Minimal allowed age is the lowest age group that has a value not equal to 0
  const dddChild = new Decimal(drug.ddd_child)
  const ddd1 = new Decimal(drug.ddd_1)
  const ddd2 = new Decimal(drug.ddd_2)
  const ddd3 = new Decimal(drug.ddd_3)
  const ddd4 = new Decimal(drug.ddd_4)
  const ddd5 = new Decimal(drug.ddd_5)
  const ddd6 = new Decimal(drug.ddd_6)
  const ddd7 = new Decimal(drug.ddd_7)
  const ddd8 = new Decimal(drug.ddd_8)
  const ddd9 = new Decimal(drug.ddd_9)
  const ddd10 = new Decimal(drug.ddd_10)
  const ddd11 = new Decimal(drug.ddd_11)
  const ddd12 = new Decimal(drug.ddd_12)
  const dddAdult = new Decimal(drug.ddd_adult)

  let minimalAllowedAge = 0
  if (dddChild.eq(0)) minimalAllowedAge = 1
  if (ddd1.eq(0)) minimalAllowedAge = 2
  if (ddd2.eq(0)) minimalAllowedAge = 3
  if (ddd3.eq(0)) minimalAllowedAge = 4
  if (ddd4.eq(0)) minimalAllowedAge = 5
  if (ddd5.eq(0)) minimalAllowedAge = 6
  if (ddd6.eq(0)) minimalAllowedAge = 7
  if (ddd7.eq(0)) minimalAllowedAge = 8
  if (ddd8.eq(0)) minimalAllowedAge = 9
  if (ddd9.eq(0)) minimalAllowedAge = 10
  if (ddd10.eq(0)) minimalAllowedAge = 11
  if (ddd11.eq(0)) minimalAllowedAge = 12
  if (ddd12.eq(0)) minimalAllowedAge = 13

  return {
    ddd_child: dddChild,
    ddd_1: ddd1,
    ddd_2: ddd2,
    ddd_3: ddd3,
    ddd_4: ddd4,
    ddd_5: ddd5,
    ddd_6: ddd6,
    ddd_7: ddd7,
    ddd_8: ddd8,
    ddd_9: ddd9,
    ddd_10: ddd10,
    ddd_11: ddd11,
    ddd_12: ddd12,
    ddd_adult: dddAdult,
    minimal_allowed_age: minimalAllowedAge
  }
}

function getConsumption (drug: IParsedDrugDDD, ageFraction: IAgeFractions, bedDays: Decimal, consumption: Decimal): {
  childrenMethodologyResult: Decimal
  standardMethodologyResult: Decimal
} {
  const sumOfAges = sumAll(
    [
      drug.ddd_child.mul(ageFraction.children[drug.minimal_allowed_age]),
      drug.ddd_1.mul(ageFraction.age_1[drug.minimal_allowed_age]),
      drug.ddd_2.mul(ageFraction.age_2[drug.minimal_allowed_age]),
      drug.ddd_3.mul(ageFraction.age_3[drug.minimal_allowed_age]),
      drug.ddd_4.mul(ageFraction.age_4[drug.minimal_allowed_age]),
      drug.ddd_5.mul(ageFraction.age_5[drug.minimal_allowed_age]),
      drug.ddd_6.mul(ageFraction.age_6[drug.minimal_allowed_age]),
      drug.ddd_7.mul(ageFraction.age_7[drug.minimal_allowed_age]),
      drug.ddd_8.mul(ageFraction.age_8[drug.minimal_allowed_age]),
      drug.ddd_9.mul(ageFraction.age_9[drug.minimal_allowed_age]),
      drug.ddd_10.mul(ageFraction.age_10[drug.minimal_allowed_age]),
      drug.ddd_11.mul(ageFraction.age_11[drug.minimal_allowed_age]),
      drug.ddd_12.mul(ageFraction.age_12[drug.minimal_allowed_age]),
      drug.ddd_adult.mul(ageFraction.adults[drug.minimal_allowed_age])
    ]
  )

  let ddd

  const adultDdd = consumption.div(drug.ddd_adult).div(bedDays).mul(new Decimal(100))
  if (drug.minimal_allowed_age === 13) {
    ddd = adultDdd
  } else {
    ddd = consumption.div(sumOfAges).div(bedDays).mul(new Decimal(100))
  }

  return {
    childrenMethodologyResult: ddd,
    standardMethodologyResult: adultDdd
  }
}

export function calculate (drug: IDrug, consumption: Decimal, ageGroups: IAgeGroups, bedDays: Decimal): IConsumption {
  const parsedDrugData = parseDrugData(drug)

  const ages = [
    ageGroups.children,
    ageGroups.age_1,
    ageGroups.age_2,
    ageGroups.age_3,
    ageGroups.age_4,
    ageGroups.age_5,
    ageGroups.age_6,
    ageGroups.age_7,
    ageGroups.age_8,
    ageGroups.age_9,
    ageGroups.age_10,
    ageGroups.age_11,
    ageGroups.age_12,
    ageGroups.adults
  ]

  // If there are no patients in the relevant age groups (>= minimal allowed age), we sum just use the adult DDD
  if (sumAll(ages.slice(parsedDrugData.minimal_allowed_age)).eq(0)) {
    return {
      childrenMethodologyResult: consumption.div(parsedDrugData.ddd_adult).div(bedDays).mul(new Decimal(100)),
      standardMethodologyResult: consumption.div(parsedDrugData.ddd_adult).div(bedDays).mul(new Decimal(100)),
      name: drug.name,
      atc: drug.atc,
      method: drug.method
    }
  }

  const sumOfAgeGroups = {
    children: sumAll(ages),
    age_1: sumAll(ages.slice(1)),
    age_2: sumAll(ages.slice(2)),
    age_3: sumAll(ages.slice(3)),
    age_4: sumAll(ages.slice(4)),
    age_5: sumAll(ages.slice(5)),
    age_6: sumAll(ages.slice(6)),
    age_7: sumAll(ages.slice(7)),
    age_8: sumAll(ages.slice(8)),
    age_9: sumAll(ages.slice(9)),
    age_10: sumAll(ages.slice(10)),
    age_11: sumAll(ages.slice(11)),
    age_12: sumAll(ages.slice(12)),
    adults: ages[13]
  }

  const fractionsOfAgeGroups: IAgeFractions = {
    children: calculateFractions(ages[0], sumOfAgeGroups, 0),
    age_1: calculateFractions(ages[1], sumOfAgeGroups, 1),
    age_2: calculateFractions(ages[2], sumOfAgeGroups, 2),
    age_3: calculateFractions(ages[3], sumOfAgeGroups, 3),
    age_4: calculateFractions(ages[4], sumOfAgeGroups, 4),
    age_5: calculateFractions(ages[5], sumOfAgeGroups, 5),
    age_6: calculateFractions(ages[6], sumOfAgeGroups, 6),
    age_7: calculateFractions(ages[7], sumOfAgeGroups, 7),
    age_8: calculateFractions(ages[8], sumOfAgeGroups, 8),
    age_9: calculateFractions(ages[9], sumOfAgeGroups, 9),
    age_10: calculateFractions(ages[10], sumOfAgeGroups, 10),
    age_11: calculateFractions(ages[11], sumOfAgeGroups, 11),
    age_12: calculateFractions(ages[12], sumOfAgeGroups, 12),
    adults: calculateFractions(ages[13], sumOfAgeGroups, 13)
  }

  return {
    ...getConsumption(parsedDrugData, fractionsOfAgeGroups, bedDays, consumption),
    name: drug.name,
    atc: drug.atc,
    method: drug.method
  }
}
