import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { AlertColor, Button, Grid, TextField, Typography } from '@mui/material'
import { Decimal } from 'decimal.js'
import { IAgeGroups } from '../interfaces/drugs'
import { PasteStatus } from '../enums/PasteStatus'
import { FileDownloadOutlined } from '@mui/icons-material'

export function AgeGroupInputScreen (
  {
    setAgeGroups,
    ageGroups,
    setBedDays,
    bedDays,
    callAlert,
    handleImportClick
  }: {
    setAgeGroups: (ageGroups: IAgeGroups) => void
    ageGroups: IAgeGroups
    setBedDays: (bedDays: Decimal) => void
    bedDays: Decimal
    callAlert: (message: string, severity: AlertColor) => void
    handleImportClick: () => void
  }): JSX.Element {
  const intl = useIntl()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    if (name === 'total_days') {
      try {
        const totalDays = new Decimal(value)
        setBedDays(totalDays)
      } catch (DecimalError) {
        if (value === '') {
          setBedDays(new Decimal(0))
        }
      }
    } else {
      try {
        setAgeGroups({ ...ageGroups, [name]: new Decimal(value) })
      } catch (DecimalError) {
        if (value === '') {
          setAgeGroups({ ...ageGroups, [name]: new Decimal(0) })
        }
      }
    }
  }

  const handlePaste = (event: React.ClipboardEvent<HTMLDivElement>, field: number): void => {
    event.preventDefault()
    let pasteStatus = PasteStatus.Success
    const text = event.clipboardData.getData('text/plain').replaceAll(',', '.')
    const lines = text.split(/\r?\n/).filter(line => line !== '')
    const parsedLines = lines.map(line => {
      try {
        return new Decimal(line)
      } catch (DecimalError) {
        return new Decimal(NaN)
      }
    })
    console.log(parsedLines)

    // Check if any of the line is not a NaN
    if (!(parsedLines.some(line => !line.isNaN()))) {
      pasteStatus = PasteStatus.Fail
      callAlert(intl.formatMessage({
        id: 'ageGroupInputScreen.pasteError',
        defaultMessage: 'Вставленный текст не содержит чисел',
        description: 'Error message when pasting text that does not contain numbers'
      }), 'error')
    }
    // Check if there are more parsed lines than age groups left to fill
    if (parsedLines.length > 14 - field) {
      if (parsedLines.length === 15 - field) {
        pasteStatus = PasteStatus.Partial
        callAlert(intl.formatMessage({
          id: 'ageGroupInputScreen.pastedWithBedDays',
          defaultMessage: 'Последняя строка принята как количество койко-дней',
          description: 'Info message when pasting text that contains one more line than age groups'
        }), 'info')
      } else {
        pasteStatus = PasteStatus.Partial
        callAlert(intl.formatMessage({
          id: 'ageGroupInputScreen.pastedWithWarning',
          defaultMessage: 'Найдено больше чисел, чем осталось для заполнения',
          description: 'Warning message when pasting text that contains more numbers than age groups left to fill'
        }), 'warning')
      }
    } else if (parsedLines.some(line => line.isNaN())) {
      pasteStatus = PasteStatus.Partial
      callAlert(intl.formatMessage({
        id: 'ageGroupInputScreen.notAllPasted',
        defaultMessage: 'Не все числа были распознаны',
        description: 'Warning message when pasting text that contains some values that could not be parsed'
      }), 'warning')
    }
    const updatedAgeGroups = { ...ageGroups }
    for (let i = field, j = 0; i < field + parsedLines.length; i++, j++) {
      if (i > 14) {
        break
      }
      if (parsedLines[j].isNaN()) {
        continue
      }
      switch (i) {
        case 0:
          updatedAgeGroups.children = parsedLines[j]
          break
        case 1:
          updatedAgeGroups.age_1 = parsedLines[j]
          break
        case 2:
          updatedAgeGroups.age_2 = parsedLines[j]
          break
        case 3:
          updatedAgeGroups.age_3 = parsedLines[j]
          break
        case 4:
          updatedAgeGroups.age_4 = parsedLines[j]
          break
        case 5:
          updatedAgeGroups.age_5 = parsedLines[j]
          break
        case 6:
          updatedAgeGroups.age_6 = parsedLines[j]
          break
        case 7:
          updatedAgeGroups.age_7 = parsedLines[j]
          break
        case 8:
          updatedAgeGroups.age_8 = parsedLines[j]
          break
        case 9:
          updatedAgeGroups.age_9 = parsedLines[j]
          break
        case 10:
          updatedAgeGroups.age_10 = parsedLines[j]
          break
        case 11:
          updatedAgeGroups.age_11 = parsedLines[j]
          break
        case 12:
          updatedAgeGroups.age_12 = parsedLines[j]
          break
        case 13:
          updatedAgeGroups.adults = parsedLines[j]
          break
        case 14:
          setBedDays(parsedLines[j])
          break
        default:
          break
      }
    }

    setAgeGroups(updatedAgeGroups)
    if (pasteStatus === PasteStatus.Success) {
      callAlert(intl.formatMessage({
        id: 'ageGroupInputScreen.pasteSuccess',
        defaultMessage: 'Вставка прошла успешно',
        description: 'Info message when successfully pasting text'
      }), 'info')
    }
  }

  return (
    <Grid mt={2} container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h6' display='inline'>
          <FormattedMessage
            id='ageGroupInputScreen.title'
            defaultMessage='Укажите количество пациентов в стационаре'
            description='Title of the age group input screen'
          />
        </Typography>
        <div style={{ float: 'right' }}>
          <Button
            onClick={handleImportClick}
            startIcon={<FileDownloadOutlined />}
          >
            <FormattedMessage id='calculator.import' defaultMessage='Импорт' />
          </Button>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='subtitle2'>
          <FormattedMessage
            id='ageGroupInputScreen.subtitle'
            defaultMessage='Отдельно для каждого возраста в диапазоне 0–12 лет и вместе для пациентов в возрасте 13 лет и старше (по умолчанию количество пациентов в каждой группе равно нулю).'
            description='Subtitle of the age group input screen'
          />
        </Typography>
      </Grid>
      <Grid item xs={12} md={6} container spacing={1}>
        <AgeGroupInputField
          label={intl.formatMessage({
            id: 'ageGroupInputScreen.children',
            defaultMessage: 'До 1 года',
            description: 'Label of the children age group input field'
          })}
          name='children'
          onChange={handleChange}
          value={ageGroups.children.toString()}
          onPaste={e => handlePaste(e, 0)}
        />
        <AgeGroupInputField
          label={intl.formatMessage({
            id: 'ageGroupInputScreen.age1',
            defaultMessage: '1 год',
            description: 'Label of the age 1 age group input field'
          })}
          name='age_1'
          onChange={handleChange}
          value={ageGroups.age_1.toString()}
          onPaste={(e) => handlePaste(e, 1)}
        />
        <AgeGroupInputField
          label={intl.formatMessage({
            id: 'ageGroupInputScreen.age2',
            defaultMessage: '2 года',
            description: 'Label of the age 2 age group input field'
          })}
          name='age_2'
          onChange={handleChange}
          value={ageGroups.age_2.toString()}
          onPaste={(e) => handlePaste(e, 2)}
        />
        <AgeGroupInputField
          label={intl.formatMessage({
            id: 'ageGroupInputScreen.age3',
            defaultMessage: '3 года',
            description: 'Label of the age 3 age group input field'
          })}
          name='age_3'
          onChange={handleChange}
          value={ageGroups.age_3.toString()}
          onPaste={(e) => handlePaste(e, 3)}
        />
        <AgeGroupInputField
          label={intl.formatMessage({
            id: 'ageGroupInputScreen.age4',
            defaultMessage: '4 года',
            description: 'Label of the age 4 age group input field'
          })}
          name='age_4'
          onChange={handleChange}
          value={ageGroups.age_4.toString()}
          onPaste={(e) => handlePaste(e, 4)}
        />
        <AgeGroupInputField
          label={intl.formatMessage({
            id: 'ageGroupInputScreen.age5',
            defaultMessage: '5 лет',
            description: 'Label of the age 5 age group input field'
          })}
          name='age_5'
          onChange={handleChange}
          value={ageGroups.age_5.toString()}
          onPaste={(e) => handlePaste(e, 5)}
        />
        <AgeGroupInputField
          label={intl.formatMessage({
            id: 'ageGroupInputScreen.age6',
            defaultMessage: '6 лет',
            description: 'Label of the age 6 age group input field'
          })}
          name='age_6'
          onChange={handleChange}
          value={ageGroups.age_6.toString()}
          onPaste={(e) => handlePaste(e, 6)}
        />
      </Grid>
      <Grid item xs={12} md={6} container spacing={1}>
        <AgeGroupInputField
          label={intl.formatMessage({
            id: 'ageGroupInputScreen.age7',
            defaultMessage: '7 лет',
            description: 'Label of the age 7 age group input field'
          })}
          name='age_7'
          onChange={handleChange}
          value={ageGroups.age_7.toString()}
          onPaste={(e) => handlePaste(e, 7)}
        />
        <AgeGroupInputField
          label={intl.formatMessage({
            id: 'ageGroupInputScreen.age8',
            defaultMessage: '8 лет',
            description: 'Label of the age 8 age group input field'
          })}
          name='age_8'
          onChange={handleChange}
          value={ageGroups.age_8.toString()}
          onPaste={(e) => handlePaste(e, 8)}
        />
        <AgeGroupInputField
          label={intl.formatMessage({
            id: 'ageGroupInputScreen.age9',
            defaultMessage: '9 лет',
            description: 'Label of the age 9 age group input field'
          })}
          name='age_9'
          onChange={handleChange}
          value={ageGroups.age_9.toString()}
          onPaste={(e) => handlePaste(e, 9)}
        />
        <AgeGroupInputField
          label={intl.formatMessage({
            id: 'ageGroupInputScreen.age10',
            defaultMessage: '10 лет',
            description: 'Label of the age 10 age group input field'
          })}
          name='age_10'
          onChange={handleChange}
          value={ageGroups.age_10.toString()}
          onPaste={(e) => handlePaste(e, 10)}
        />
        <AgeGroupInputField
          label={intl.formatMessage({
            id: 'ageGroupInputScreen.age11',
            defaultMessage: '11 лет',
            description: 'Label of the age 11 age group input field'
          })}
          name='age_11'
          onChange={handleChange}
          value={ageGroups.age_11.toString()}
          onPaste={(e) => handlePaste(e, 11)}
        />
        <AgeGroupInputField
          label={intl.formatMessage({
            id: 'ageGroupInputScreen.age12',
            defaultMessage: '12 лет',
            description: 'Label of the age 12 age group input field'
          })}
          name='age_12'
          onChange={handleChange}
          value={ageGroups.age_12.toString()}
          onPaste={(e) => handlePaste(e, 12)}
        />
        <AgeGroupInputField
          label={intl.formatMessage({
            id: 'ageGroupInputScreen.adults',
            defaultMessage: '13 лет и старше, включая взрослых',
            description: 'Label of the adults age group input field'
          })}
          name='adults'
          onChange={handleChange}
          value={ageGroups.adults.toString()}
          onPaste={(e) => handlePaste(e, 13)}
        />

      </Grid>
      <Grid item xs={12}>
        <Typography variant='h6'>
          <FormattedMessage
            id='ageGroupInputScreen.bedDaysTitle'
            defaultMessage='Укажите общее количество койко-дней, проведённых вышеуказанными пациентами в стационаре'
            description='Title of the bed days input screen'
          />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          onPaste={(e) => handlePaste(e, 14)}
          label={intl.formatMessage({
            id: 'ageGroupInputScreen.bedDays',
            defaultMessage: 'Количество койко-дней',
            description: 'Label of the bed days input field'
          })}
          name='total_days'
          variant='standard'
          onChange={handleChange}
          required
          value={bedDays.toString() === '0' ? '' : bedDays.toString()}
        />
      </Grid>
    </Grid>
  )
}

function AgeGroupInputField ({
  label,
  name,
  onChange,
  value,
  onPaste
}: {
  label: string
  name: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  value: string
  onPaste: (e: React.ClipboardEvent<HTMLInputElement>) => void
}) {
  return (
    <Grid item xs={12}>
      <TextField
        fullWidth
        onPaste={onPaste}
        label={label}
        name={name}
        variant='standard'
        onChange={onChange}
        placeholder='0'
        value={value === 'NaN' ? '' : value === '0' ? '' : value}
      />
    </Grid>
  )
}
