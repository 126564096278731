import logo from '../images/logo.png'
import React from 'react'

export default function AppBarLogo (): JSX.Element {
  return <div style={
    {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
      height: '100%',
      width: '100%'
    }
  }
  >
    <img
      src={logo} alt="AMRCalc" style={
      {
        height: '50px',
        marginRight: '10px'
      }
    }
    />
  </div>
}
