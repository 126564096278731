import { Button, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import { useAxios } from '../providers/AxiosProvider'
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined'
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined'
import { ILocales } from '../interfaces/locales'
import LocaleCard from '../molecules/LocaleCard'
import CreateLocaleDialog from '../organisms/CreateLocaleDialog'
import ChangePasswordDialog from '../organisms/ChangePasswordDialog'

export default function Locales (): JSX.Element {
  const [createLocaleOpen, setCreateLocaleOpen] = React.useState(false)
  const [changePasswordOpen, setChangePasswordOpen] = React.useState(false)

  const axios = useAxios()
  const locales = useQuery<ILocales, Error>('locales', async () => {
    const { data } = await axios.get('/locales')
    return data
  })

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Button
            variant='outlined'
            color='primary'
            fullWidth
            startIcon={
              <CreateNewFolderOutlinedIcon />
                        }
            onClick={
                            () => setCreateLocaleOpen(true)
                        }
          >
            Создать новую локаль
          </Button>
          <CreateLocaleDialog open={createLocaleOpen} onClose={() => setCreateLocaleOpen(false)} />
        </Grid>
        {locales.isLoading &&
          <Grid item xs={12}>
            <Typography variant='body1'>
              Загрузка...
            </Typography>
          </Grid>} {locales.isError &&
            <Grid item xs={12}>
              <Typography variant='body1'>
                Ошибка загрузки локалей.
              </Typography>
            </Grid>}
        {locales.data !== undefined &&
          <Grid item xs={12}>
            <Grid container spacing={3}>
              {locales.data.locales.map(p => (
                <Grid key={p.name} item xs={12}>
                  <Link to={`/admin/locales/${p.name}`} style={{ textDecoration: 'none' }}>
                    <LocaleCard locale={p} />
                  </Link>
                </Grid>
              ))}
            </Grid>
          </Grid>}
        <Grid item xs={12}>
          <Button
            variant='outlined'
            color='primary'
            fullWidth
            startIcon={
              <VpnKeyOutlinedIcon />
                        }
            onClick={
                            () => setChangePasswordOpen(true)
                        }
          >
            Сменить пароль
          </Button>
          <ChangePasswordDialog open={changePasswordOpen} onClose={() => setChangePasswordOpen(false)} />
        </Grid>
      </Grid>
    </Container>
  )
}
