import { Decimal } from 'decimal.js'

export enum InjectionMethod {
  Implant = 'Implant',
  Inhalation = 'Inhal',
  Instillation = 'Instill',
  Nasal = 'N',
  Oral = 'O',
  Parenteral = 'P',
  Rectal = 'R',
  Sublingual = 'SL',
  Transdermal = 'TD',
  Vaginal = 'V',
  Combined = 'COMBINED'
}

export interface IDrug {
  name: string
  atc: string
  id?: number
  method: InjectionMethod
  ddd_adult: string
  ddd_child: string
  ddd_1: string
  ddd_2: string
  ddd_3: string
  ddd_4: string
  ddd_5: string
  ddd_6: string
  ddd_7: string
  ddd_8: string
  ddd_9: string
  ddd_10: string
  ddd_11: string
  ddd_12: string
}

export interface IDrugs {
  locale: string
  drugs: IDrug[]
}

export interface IAgeGroups {
  children: Decimal
  age_1: Decimal
  age_2: Decimal
  age_3: Decimal
  age_4: Decimal
  age_5: Decimal
  age_6: Decimal
  age_7: Decimal
  age_8: Decimal
  age_9: Decimal
  age_10: Decimal
  age_11: Decimal
  age_12: Decimal
  adults: Decimal
}

export interface ISelectedDrugData {
  drug: IDrug
  consumption: Decimal
  current_string: string
  is_valid: boolean
}

export interface IConsumption {
  name: string
  atc: string
  method: InjectionMethod
  childrenMethodologyResult: Decimal
  standardMethodologyResult: Decimal
}

export interface IExportRow {
  name: string
  method: InjectionMethod
  atc: string
  consumption: Decimal
  childrenMethodologyResult: Decimal
  standardMethodologyResult: Decimal
}

export interface IImportRow {
  name: string
  method: InjectionMethod
  atc: string
  consumption: Decimal
}
