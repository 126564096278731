import React from 'react'
import { useQuery } from 'react-query'
import { FormattedMessage, useIntl } from 'react-intl'
import { useAxios } from '../providers/AxiosProvider'
import {
  Alert,
  Box,
  Button,
  Container,
  Grid,
  Stepper,
  Step,
  StepLabel,
  Snackbar,
  AlertColor,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@mui/material'
import { Decimal } from 'decimal.js'
import { IDrugs, ISelectedDrugData, IAgeGroups, IDrug, IExportRow, IImportRow } from '../interfaces/drugs'
import { calculate } from '../calculator/calculator'
import { transformCalculationResults } from '../calculator/transformResults'
import { Chart as ChartJS, registerables } from 'chart.js'
import { AmrConsumptionInputScreen } from './AmrConsumptionInputScreen'
import { AgeGroupInputScreen } from './AgeGroupInputScreen'
import { ResultsScreen } from './ResultsScreen'
import { exportDataToTable, importDataFromExcel } from '../utils/exportData'
import DeleteSweepOutlinedIcon from '@mui/icons-material/DeleteSweepOutlined'
import { getInjectionMethodName, getInjectionMethodNameFromString } from '../utils/getInjectionMethodName'
import Fuse from 'fuse.js'
import { refreshSelectedDrugs } from '../utils/refreshSelectedDrugs'

ChartJS.register(...registerables)

interface IImportDrugResult {
  inputString: string
  inputConsumption: Decimal
  searchResult: Array<Fuse.FuseResult<IDrug>>
  selectedItem: number
  drugOverride: IDrug | null
}

export default function Calculator (props: {
  locale: string
}): JSX.Element {
  const intl = useIntl()

  const axios = useAxios()

  const [activeStep, setActiveStep] = React.useState(0)

  const steps = [
    intl.formatMessage({ id: 'calculator.step.patients', defaultMessage: 'Пациенты' }),
    intl.formatMessage({ id: 'calculator.step.drugs', defaultMessage: 'Препараты' }),
    intl.formatMessage({ id: 'calculator.step.results', defaultMessage: 'Результаты' })
  ]

  const [ageGroups, setAgeGroups] = React.useState<IAgeGroups>({
    children: new Decimal(0),
    age_1: new Decimal(0),
    age_2: new Decimal(0),
    age_3: new Decimal(0),
    age_4: new Decimal(0),
    age_5: new Decimal(0),
    age_6: new Decimal(0),
    age_7: new Decimal(0),
    age_8: new Decimal(0),
    age_9: new Decimal(0),
    age_10: new Decimal(0),
    age_11: new Decimal(0),
    age_12: new Decimal(0),
    adults: new Decimal(0)
  })

  const [bedDays, setBedDays] = React.useState(new Decimal(0))

  const [selectedDrugs, setSelectedDrugs] = React.useState<ISelectedDrugData[]>([])

  const [addDrugValue, setAddDrugValue] = React.useState<IDrug | null>(null)
  const [addDrugInputValue, setAddDrugInputValue] = React.useState<string>('')

  const [resultsTab, setResultsTab] = React.useState(0)
  const [groupByInjectionMethod, setGroupByInjectionMethod] = React.useState(false)
  const [atcGroupLevel, setAtcGroupLevel] = React.useState(5)

  const [isAlertOpen, setIsAlertOpen] = React.useState(false)
  const [alertMessage, setAlertMessage] = React.useState('')
  const [alertSeverity, setAlertSeverity] = React.useState<AlertColor>('info')

  const [openImportDialog, setOpenImportDialog] = React.useState(false)
  const [openClearDataDialog, setOpenClearDataDialog] = React.useState(false)

  const [openImportFailedDialog, setOpenImportFailedDialog] = React.useState(false)
  const [importDialogValue, setImportDialogValue] = React.useState<IImportDrugResult[]>([])

  // Load bed days, age groups and drugs from local storage
  // Key is the locale
  React.useEffect(() => {
    const ageGroups = localStorage.getItem(`ageGroups-${props.locale}`)
    if (ageGroups) {
      const parsedAgeGroups = JSON.parse(ageGroups)
      setAgeGroups({
        children: new Decimal(parsedAgeGroups.children),
        age_1: new Decimal(parsedAgeGroups.age_1),
        age_2: new Decimal(parsedAgeGroups.age_2),
        age_3: new Decimal(parsedAgeGroups.age_3),
        age_4: new Decimal(parsedAgeGroups.age_4),
        age_5: new Decimal(parsedAgeGroups.age_5),
        age_6: new Decimal(parsedAgeGroups.age_6),
        age_7: new Decimal(parsedAgeGroups.age_7),
        age_8: new Decimal(parsedAgeGroups.age_8),
        age_9: new Decimal(parsedAgeGroups.age_9),
        age_10: new Decimal(parsedAgeGroups.age_10),
        age_11: new Decimal(parsedAgeGroups.age_11),
        age_12: new Decimal(parsedAgeGroups.age_12),
        adults: new Decimal(parsedAgeGroups.adults)
      })
    }

    const bedDays = localStorage.getItem(`bedDays-${props.locale}`)
    if (bedDays) {
      setBedDays(new Decimal(bedDays))
    }

    const selectedDrugs = localStorage.getItem(`selectedDrugs-${props.locale}`)
    if (selectedDrugs) {
      const parsedSelectedDrugs = JSON.parse(selectedDrugs)
      setSelectedDrugs(parsedSelectedDrugs.map((drug: ISelectedDrugData): ISelectedDrugData => ({
        ...drug,
        consumption: new Decimal(drug.consumption)
      })))
    } else {
      setSelectedDrugs([])
    }
  }, [props.locale])

  const handleBedDaysChange = (days: Decimal): void => {
    setBedDays(days)
    localStorage.setItem(`bedDays-${props.locale}`, days.toString())
  }

  const handleAgeGroupChange = (ageGroups: IAgeGroups): void => {
    setAgeGroups(ageGroups)
    localStorage.setItem(`ageGroups-${props.locale}`, JSON.stringify(ageGroups))
  }

  const handleSelectedDrugsChange = (selectedDrugs: ISelectedDrugData[]): void => {
    setSelectedDrugs(selectedDrugs)
    localStorage.setItem(`selectedDrugs-${props.locale}`, JSON.stringify(selectedDrugs))
  }

  const importDataFromFile = React.useCallback(async (file: File, drugs: IDrug[] | undefined) => {
    setOpenImportDialog(false)
    setAlertSeverity('info')
    setAlertMessage('Импорт начат, подождите...')
    setIsAlertOpen(true)
    const data = await importDataFromExcel(file).catch(error => {
      setAlertSeverity('error')
      setAlertMessage('Ошибка импорта. Текущие данные не изменены.')
      setIsAlertOpen(true)
      console.error(error)
    })
    console.log(data)
    if (data === undefined) {
      setAlertSeverity('error')
      setAlertMessage('Ошибка импорта. Текущие данные не изменены.')
      setIsAlertOpen(true)
      return
    }
    handleAgeGroupChange(data.ageGroups)
    handleBedDaysChange(data.bedDays)
    let skippedDrugs = 0
    const parsedDrugs: ISelectedDrugData[] = []
    const unparsedDrugs: IImportRow[] = []
    data.drugs.forEach((drug: IImportRow): void => {
      if (drugs === undefined) {
        return
      }
      let foundDrug = drugs.find(d => d.name === drug.name && d.atc === drug.atc && d.method === drug.method)
      // If exact match not found, try matching by ATC and injection method
      foundDrug = foundDrug === undefined ? drugs.find(d => d.atc === drug.atc && d.method === drug.method) : foundDrug
      // If match still not found, search by ATC only. But if there are multiple matches, skip this drug
      if (foundDrug === undefined) {
        const drugsByAtc = drugs.filter(d => d.atc === drug.atc)
        if (drugsByAtc.length === 1) {
          foundDrug = drugsByAtc[0]
        } else {
          // If match still not found, defer it to fuzzy search.
          unparsedDrugs.push(drug)
        }
      }

      if (foundDrug === undefined) {
        console.warn(`Drug ${drug.name} not found`)
        skippedDrugs++
      }
      if (foundDrug !== undefined) {
        parsedDrugs.push({
          consumption: drug.consumption,
          drug: foundDrug,
          current_string: drug.consumption.toString(),
          is_valid: true
        })
      }
    }
    )
    handleSelectedDrugsChange(parsedDrugs)
    if (skippedDrugs > 0) {
      setAlertSeverity('warning')
      setAlertMessage(`Импорт завершен. Не найдено ${skippedDrugs} препаратов.`)
      setIsAlertOpen(true)
      if (drugs !== undefined) {
        const fuse = new Fuse(drugs.filter(drug => !selectedDrugs.some(selectedDrug => selectedDrug.drug.id === drug.id)), {
          keys: [
            { name: 'name', weight: 2 },
            { name: 'atc', weight: 3 },
            { name: 'injectionMethod', getFn: drug => getInjectionMethodName(drug.method, intl) }
          ],
          threshold: 0.9,
          includeScore: true
        })

        let fuzzySearchResults: IImportDrugResult[] = unparsedDrugs.map(drug => {
          const injectionMethod = getInjectionMethodNameFromString(drug.method, intl)
          const searchString = `${drug.name} ${drug.atc} ${injectionMethod}`

          const searchResult: Array<Fuse.FuseResult<IDrug>> = fuse.search(searchString, { limit: 3 }).filter(result => result.score !== undefined ? result.score < 0.4 : true)
          return {
            inputString: searchString,
            inputConsumption: drug.consumption,
            searchResult,
            selectedItem: searchResult.length > 0 ? 0 : -1,
            drugOverride: null
          }
        })

        fuzzySearchResults = fuzzySearchResults.filter(result => result.inputString !== '')
        setImportDialogValue(fuzzySearchResults)
        setOpenImportFailedDialog(true)
      }
    } else {
      setAlertSeverity('success')
      setAlertMessage('Импорт завершён')
      setIsAlertOpen(true)
    }
  }, [])

  const drugs = useQuery<IDrugs, Error>(['drugs', props.locale], async () => {
    const { data } = await axios.get(`/drugs/${props.locale}`)
    return data
  }, {
    onSuccess: (data) => {
      setSelectedDrugs(refreshSelectedDrugs(data.drugs, selectedDrugs))
    }
  })

  const calculatedConsumptions = selectedDrugs.map(drug => {
    return calculate(drug.drug, drug.consumption, ageGroups, bedDays)
  })

  const exportData = (fileType: 'xlsx' | 'ods' | 'csv'): void => {
    const data: IExportRow[] = calculatedConsumptions.map((drug, index) => {
      return {
        ...drug,
        consumption: selectedDrugs[index].consumption
      }
    })
    const date = new Date().toISOString()
    const fileName = `AMR_${date.replace(/[-:.]/g, '')}`
    exportDataToTable(ageGroups, bedDays, data, '1.0', date, fileName, fileType)
  }

  const callAlert = (message: string, severity: AlertColor): void => {
    setAlertMessage(message)
    setAlertSeverity(severity)
    setIsAlertOpen(true)
  }

  const clearData = (): void => {
    setAgeGroups({
      children: new Decimal(0),
      age_1: new Decimal(0),
      age_2: new Decimal(0),
      age_3: new Decimal(0),
      age_4: new Decimal(0),
      age_5: new Decimal(0),
      age_6: new Decimal(0),
      age_7: new Decimal(0),
      age_8: new Decimal(0),
      age_9: new Decimal(0),
      age_10: new Decimal(0),
      age_11: new Decimal(0),
      age_12: new Decimal(0),
      adults: new Decimal(0)
    })
    setBedDays(new Decimal(0))
    setSelectedDrugs([])
    localStorage.removeItem(`ageGroups-${props.locale}`)
    localStorage.removeItem(`bedDays-${props.locale}`)
    localStorage.removeItem(`selectedDrugs-${props.locale}`)
    setActiveStep(0)
    callAlert('Данные очищены', 'info')
  }

  const mayContinue = (): boolean => {
    if (activeStep === 0) {
      const zero = new Decimal('0')
      if (Object.entries(ageGroups).some(([, value]) => value.lt(zero)) || bedDays.lte(zero) || !Object.entries(ageGroups).some(([, value]) => value.gt(zero))) {
        return false
      }
      return true
    }
    if (activeStep === 1) {
      if (selectedDrugs.length === 0) return false
      return true
    }
    if (activeStep === 2) {
      return false
    }
    return false
  }

  const groupedConsumptions = transformCalculationResults(calculatedConsumptions, groupByInjectionMethod, atcGroupLevel)

  return (
    <Container maxWidth='xl'>
      <Box>
        <Stepper activeStep={activeStep}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      {activeStep === 0 &&
        <AgeGroupInputScreen
          ageGroups={ageGroups}
          bedDays={bedDays}
          callAlert={callAlert}
          setAgeGroups={handleAgeGroupChange}
          setBedDays={handleBedDaysChange}
          handleImportClick={() => setOpenImportDialog(true)}
        />}

      {activeStep === 1 && drugs.data !== undefined && (
        <AmrConsumptionInputScreen
          selectedDrugs={selectedDrugs}
          setSelectedDrugs={handleSelectedDrugsChange}
          drugs={drugs.data}
          addDrugValue={addDrugValue}
          setAddDrugValue={setAddDrugValue}
          setAddDrugInputValue={setAddDrugInputValue}
          addDrugInputValue={addDrugInputValue}
          callAlert={callAlert}
          handleImportClick={() => setOpenImportDialog(true)}
        />
      )}
      {activeStep === 2 && drugs.data !== undefined && (
        <ResultsScreen
          groupedConsumptions={groupedConsumptions}
          resultsTab={resultsTab}
          setResultsTab={setResultsTab}
          groupByInjectionMethod={groupByInjectionMethod}
          setGroupByInjectionMethod={setGroupByInjectionMethod}
          atcGroupLevel={atcGroupLevel}
          setAtcGroupLevel={setAtcGroupLevel}
          exportCallback={exportData}
        />
      )}
      <Grid container spacing={3} mt={2} mb={4}>
        <Grid item xs={12} md={8} display='flex' flexDirection='row'>
          <Box display='flex' mr={2}>
            <Button onClick={() => setActiveStep(activeStep - 1)} disabled={activeStep === 0}>
              <FormattedMessage id='calculator.back' defaultMessage='Назад' />
            </Button>
          </Box>
          <Box display='flex'>
            <Button onClick={() => setActiveStep(activeStep + 1)} disabled={!mayContinue()}>
              <FormattedMessage id='calculator.next' defaultMessage='Далее' />
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={4} display='flex' flexDirection='row'>
          <Box flexGrow={1} />
          <Box display='flex'>
            <Button
              onClick={() => setOpenClearDataDialog(true)}
              color='error'
              startIcon={<DeleteSweepOutlinedIcon />}
            >
              <FormattedMessage id='calculator.clear' defaultMessage='Очистить данные' />
            </Button>
          </Box>
        </Grid>

      </Grid>
      <Snackbar
        open={isAlertOpen}
        autoHideDuration={6000}
        onClose={() => setIsAlertOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={() => setIsAlertOpen(false)} severity={alertSeverity}>
          {alertMessage}
        </Alert>
      </Snackbar>
      <Dialog open={openImportDialog} onClose={() => setOpenImportDialog(false)}>
        <DialogTitle>
          <FormattedMessage id='calculator.import.title' defaultMessage='Импорт из файла' />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormattedMessage id='calculator.import.description' defaultMessage='Выберите файл для импорта' />
          </DialogContentText>
          <input
            type='file' onChange={(e) => {
              const file = e.target.files?.[0]
              if (file != null) {
                importDataFromFile(file, drugs.data === undefined ? undefined : drugs.data.drugs)
                setOpenImportDialog(false)
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => exportData('xlsx')} color='primary'>
            <FormattedMessage id='calculator.template' defaultMessage='Шаблон' />
          </Button>
          <Box flexGrow={1} />
          <Button onClick={() => setOpenImportDialog(false)} color='primary'>
            <FormattedMessage id='calculator.import.cancel' defaultMessage='Отмена' />
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openClearDataDialog} onClose={() => setOpenClearDataDialog(false)}>
        <DialogTitle>
          <FormattedMessage id='calculator.clear.title' defaultMessage='Очистить данные' />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormattedMessage id='calculator.clear.description' defaultMessage='Вы уверены, что хотите очистить все данные?' />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => exportData('xlsx')} color='primary'>
            <FormattedMessage id='calculator.clear.export' defaultMessage='Экспортировать' />
          </Button>
          <Box flexGrow={1} />
          <Button onClick={() => setOpenClearDataDialog(false)} color='primary'>
            <FormattedMessage id='calculator.clear.cancel' defaultMessage='Отмена' />
          </Button>
          <Button
            onClick={() => {
              setOpenClearDataDialog(false)
              clearData()
            }} color='error' autoFocus
          >
            <FormattedMessage id='calculator.clear.confirm' defaultMessage='Очистить' />
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openImportFailedDialog} maxWidth='lg'>
        <DialogTitle>
          <FormattedMessage
            id='amrConsumptionInputScreen.importUnparsedDialogTitle'
            defaultMessage='Нераспознанные строки'
            description='Paste data for input dialog title'
          />
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            <FormattedMessage
              id='amrConsumptionInputScreen.importUnparsedDialogDescription'
              defaultMessage='Выполнили поиск по каждой строке в документе, но не все удалось распознать автоматически. Убедитесь, что каждая строка была распознана правильно. Выберите "Игнорировать", если строку надо пропустить. Если нужного препарата нет в списке, добавьте его вручную позже.'
              description='Paste data for input dialog description'
            />
          </DialogContentText>
          {importDialogValue.map((row, index) => (
            <Box key={index} mt={1}>
              <Typography variant='h6'>
                {row.inputString}
              </Typography>
              <RadioGroup
                name={`pasteDialogRadioGroup_${index}`}
                value={row.selectedItem}
                onChange={e => {
                  setImportDialogValue([...importDialogValue.slice(0, index), { ...importDialogValue[index], selectedItem: Number(e.target.value) }, ...importDialogValue.slice(index + 1)])
                }}
              >
                {row.searchResult.map((option, index) => (
                  <FormControlLabel
                    key={index}
                    value={index}
                    control={<Radio size='small' />}
                    label={`${option.item.name} (${option.item.atc}) - ${getInjectionMethodName(option.item.method, intl)}`}
                  />
                ))}
                <FormControlLabel
                  value={-1}
                  control={<Radio size='small' />}
                  label={intl.formatMessage({
                    id: 'amrConsumptionInputScreen.pasteDialog.ignore',
                    defaultMessage: 'Игнорировать',
                    description: 'Ignore'
                  })}
                />

              </RadioGroup>
            </Box>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenImportFailedDialog(false)}>
            <FormattedMessage
              id='amrConsumptionInputScreen.pasteDialog.cancel'

              defaultMessage='Отмена'
              description='Cancel button'
            />
          </Button>
          <Button onClick={() => {
            setOpenImportFailedDialog(false)
            const drugsToAdd: ISelectedDrugData[] = []
            // TODO: Add drugs to selectedDrugs
            importDialogValue.filter(row => row.selectedItem !== -1).forEach(row => {
              console.log(row)
              if (row.selectedItem === -2) {
                if (row.drugOverride !== null) {
                  if (selectedDrugs.some(selectedDrug => selectedDrug.drug.id === row.drugOverride?.id)) {
                    return
                  }
                  drugsToAdd.push({
                    drug: row.drugOverride,
                    consumption: row.inputConsumption.isNaN() ? new Decimal(0) : row.inputConsumption,
                    is_valid: true,
                    current_string: row.inputConsumption.isNaN() ? '' : row.inputConsumption.toString()
                  })
                }
              } else if (row.selectedItem >= 0) {
                console.log(row.searchResult[row.selectedItem])
                if (selectedDrugs.some(selectedDrug => selectedDrug.drug.id === row.searchResult[row.selectedItem].item.id || drugsToAdd.some(drugToAdd => drugToAdd.drug.id === row.searchResult[row.selectedItem].item.id))) {
                  console.log('Drug already selected')
                  return
                }
                drugsToAdd.push({
                  drug: row.searchResult[row.selectedItem].item,
                  consumption: row.inputConsumption.isNaN() ? new Decimal(0) : row.inputConsumption,
                  is_valid: true,
                  current_string: row.inputConsumption.isNaN() ? '' : row.inputConsumption.toString()
                })
              }
              setSelectedDrugs([...selectedDrugs, ...drugsToAdd])
            })
          }}
          >
            <FormattedMessage
              id='amrConsumptionInputScreen.pasteDialog.add'
              defaultMessage='Добавить'
              description='Add button'
            />
          </Button>
        </DialogActions>

      </Dialog>
    </Container>
  )
}
