import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Grid, TextField, Typography, TableContainer, Table, TableHead, TableRow, TableCell, Autocomplete, TableBody, IconButton, FilterOptionsState, Dialog, DialogTitle, DialogContent, Box, RadioGroup, Radio, FormControlLabel, DialogActions, Button, DialogContentText, AlertColor } from '@mui/material'
import { Decimal } from 'decimal.js'
import { IDrugs, ISelectedDrugData, IDrug } from '../interfaces/drugs'
import { DeleteOutlined, FileDownloadOutlined } from '@mui/icons-material'
import { getInjectionMethodName, getInjectionMethodNameFromString } from '../utils/getInjectionMethodName'
import Fuse from 'fuse.js'
import { PasteStatus } from '../enums/PasteStatus'

interface IPasteDrugResult {
  inputString: string
  inputConsumption: Decimal
  searchResult: Array<Fuse.FuseResult<IDrug>>
  selectedItem: number
  drugOverride: IDrug | null
}

export function AmrConsumptionInputScreen ({
  selectedDrugs,
  setSelectedDrugs,
  drugs,
  addDrugValue,
  setAddDrugValue,
  setAddDrugInputValue,
  addDrugInputValue,
  callAlert,
  handleImportClick
}: {
  selectedDrugs: ISelectedDrugData[]
  setSelectedDrugs: (selectedDrugs: ISelectedDrugData[]) => void
  drugs: IDrugs
  addDrugValue: IDrug | null
  setAddDrugValue: React.Dispatch<React.SetStateAction<IDrug | null>>
  setAddDrugInputValue: React.Dispatch<React.SetStateAction<string>>
  addDrugInputValue: string
  callAlert: (message: string, severity: AlertColor) => void
  handleImportClick: () => void
}): JSX.Element {
  const [pasteDialogOpen, setPasteDialogOpen] = React.useState(false)
  const [pasteDialogValue, setPasteDialogValue] = React.useState<IPasteDrugResult[]>([])

  const intl = useIntl()

  const fuse = new Fuse(drugs.drugs.filter(drug => !selectedDrugs.some(selectedDrug => selectedDrug.drug.id === drug.id)), {
    keys: [
      { name: 'name', weight: 2 },
      { name: 'atc', weight: 3 },
      { name: 'injectionMethod', getFn: drug => getInjectionMethodName(drug.method, intl) }
    ],
    threshold: 0.9,
    includeScore: true
  })

  const filterOptions = (options: IDrug[], state: FilterOptionsState<IDrug>): IDrug[] => {
    if (!state.inputValue) {
      return options
    }
    fuse.setCollection(options)
    const filteredOptions = fuse.search(state.inputValue)
    return filteredOptions.map(option => option.item)
  }

  const parseStringToDecimal = (inputString: string | undefined): Decimal => {
    if (inputString === undefined) {
      return new Decimal(NaN)
    }
    try {
      return new Decimal(inputString.replace(',', '.'))
    } catch (DecimalError) {
      return new Decimal(NaN)
    }
  }

  const handleDrugsPaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault()
    console.log(event.clipboardData.getData('text'))
    const lines = event.clipboardData.getData('text/plain').split(/\r?\n/).filter(line => line !== '')
    parseDrugsPaste(lines)
  }

  const parseDrugsPaste = (lines: string[]) => {
    let results: IPasteDrugResult[] = lines.map(line => {
      const fields = line.split('\t')
      console.log(fields)
      // Find first field which is a number
      const consumptionAmountField = fields.find((field, index) => index !== 0 && !isNaN(Number(field.replace(',', '.'))))
      const consumptionAmount = parseStringToDecimal(consumptionAmountField)
      const injectionMethodField = fields.find(field => field.length === 1 || field.length === 2)
      if (injectionMethodField) {
        const injectionMethod = getInjectionMethodNameFromString(injectionMethodField, intl)
        if (injectionMethod) {
          fields[fields.indexOf(injectionMethodField)] = injectionMethod
        }
      }
      const searchString = fields.filter(field => isNaN(Number(field.replace(',', '.')))).join(' ')

      const searchResult = fuse.search(searchString, { limit: 3 }).filter(result => result.score !== undefined ? result.score < 0.4 : true)
      return {
        inputConsumption: consumptionAmount,
        inputString: searchString,
        searchResult,
        selectedItem: searchResult.length > 0 ? 0 : -1,
        drugOverride: null
      }
    })
    results = results.filter(result => result.inputString !== '')
    console.log(results)
    setPasteDialogValue(results)
    setPasteDialogOpen(true)
  }

  const handleConsumptionPaste = (event: React.ClipboardEvent<HTMLDivElement>, field: number): void => {
    event.preventDefault()
    let pasteStatus = PasteStatus.Success
    const text = event.clipboardData.getData('text/plain').replaceAll(',', '.')
    const lines = text.split(/\r?\n/).filter(line => line !== '')
    const parsedLines = lines.map(line => {
      try {
        return new Decimal(line)
      } catch (DecimalError) {
        return new Decimal(NaN)
      }
    })

    if (!(parsedLines.some(line => !line.isNaN()))) {
      pasteStatus = PasteStatus.Fail
      callAlert(intl.formatMessage({
        id: 'amrConsumptionInputScreen.pasteError',
        defaultMessage: 'Вставленный текст не содержит чисел',
        description: 'Error message when pasting text that does not contain numbers'
      }), 'error')
    }

    // Check if there are more parsed lines than age groups left to fill
    if (parsedLines.length > selectedDrugs.length - field) {
      pasteStatus = PasteStatus.Partial
      callAlert(intl.formatMessage({
        id: 'ageGroupInputScreen.pastedWithWarning',
        defaultMessage: 'Найдено больше чисел, чем осталось для заполнения',
        description: 'Warning message when pasting text that contains more numbers than age groups left to fill'
      }), 'warning')
    } else if (parsedLines.some(line => line.isNaN())) {
      pasteStatus = PasteStatus.Partial
      callAlert(intl.formatMessage({
        id: 'ageGroupInputScreen.notAllPasted',
        defaultMessage: 'Не все числа были распознаны',
        description: 'Warning message when pasting text that contains some values that could not be parsed'
      }), 'warning')
    }
    const newSelectedDrugs = selectedDrugs.map((selectedDrug, index) => {
      if (index >= field && index < field + parsedLines.length && !parsedLines[index - field].isNaN()) {
        return {
          ...selectedDrug,
          consumption: parsedLines[index - field],
          current_string: parsedLines[index - field].toString()
        }
      } else {
        return selectedDrug
      }
    })
    console.log(newSelectedDrugs)
    setSelectedDrugs(newSelectedDrugs)
    if (pasteStatus === PasteStatus.Success) {
      callAlert(intl.formatMessage({
        id: 'ageGroupInputScreen.pasteSuccess',
        defaultMessage: 'Вставка прошла успешно',
        description: 'Info message when successfully pasting text'
      }), 'info')
    }
  }

  return (
    <Grid mt={2} container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h6' display='inline'>
          <FormattedMessage
            id='amrConsumptionInputScreen.title'
            defaultMessage='Выберите антимикробные препараты и укажите их потребление в граммах'
            description='Title of the AMR consumption input screen'
          />
        </Typography>
        <div style={{ float: 'right' }}>
          <Button
            onClick={handleImportClick}
            startIcon={<FileDownloadOutlined />}
          >
            <FormattedMessage id='calculator.import' defaultMessage='Импорт' />
          </Button>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='subtitle2'>
          <FormattedMessage
            id='amrConsumptionInputScreen.subtitle'
            defaultMessage='Для комбинированных препаратов, содержащих в составе один антимикробный препарат, следует вносить только его количество'
            description='Subtitle of the AMR consumption input screen'
          />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TableContainer>
          <Table size='small'>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>
                  <FormattedMessage
                    id='amrConsumptionInputScreen.tableHeaders.IDName'
                    defaultMessage='МНН'
                    description='International drug name'
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id='amrConsumptionInputScreen.tableHeaders.injectionMethod'
                    defaultMessage='Путь введения'
                    description='Injection method'
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id='amrConsumptionInputScreen.tableHeaders.ATCCode'
                    defaultMessage='Код АТС'
                    description='ATC code'
                  />
                </TableCell>
                <TableCell>
                  <FormattedMessage
                    id='amrConsumptionInputScreen.tableHeaders.consumptionInGrams'
                    defaultMessage='Потребление, г'
                    description='Consumption in grams'
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedDrugs.map((drug, index) => (
                <TableRow key={index}>
                  <TableCell padding='checkbox'>
                    <IconButton
                      aria-label='delete'
                      onClick={() => {
                        setSelectedDrugs(selectedDrugs.filter(d => d.drug.id !== drug.drug.id))
                      }}
                    >
                      <DeleteOutlined />
                    </IconButton>
                  </TableCell>
                  <TableCell>{drug.drug.name}</TableCell>
                  <TableCell>{getInjectionMethodName(drug.drug.method, intl)}</TableCell>
                  <TableCell>{drug.drug.atc}</TableCell>
                  <TableCell>
                    <TextField
                      fullWidth
                      name='consumption'
                      variant='standard'
                      onPaste={(e) => handleConsumptionPaste(e, index)}
                      onChange={e => {
                        const newValue = e.target.value.replace(',', '.')
                        try {
                          setSelectedDrugs([...selectedDrugs.slice(0, index), { ...selectedDrugs[index], current_string: newValue, consumption: new Decimal(newValue) }, ...selectedDrugs.slice(index + 1)])
                        } catch (DecimalError) {
                          setSelectedDrugs([...selectedDrugs.slice(0, index), { ...selectedDrugs[index], current_string: newValue, consumption: new Decimal(NaN) }, ...selectedDrugs.slice(index + 1)])
                        }
                      }}
                      required
                      error={selectedDrugs[index].consumption.isNaN()}
                      value={selectedDrugs[index].current_string}
                    />
                  </TableCell>

                </TableRow>
              ))}
              <TableRow>
                <TableCell padding='checkbox' />
                <TableCell colSpan={5}>
                  <Autocomplete
                    size='small'
                    id='select-drugs'
                    options={// Filter out drags which are already selected
                      drugs.drugs.filter(drug => !selectedDrugs.some(selectedDrug => selectedDrug.drug.id === drug.id))
                    }
                    autoHighlight
                    onPaste={handleDrugsPaste}
                    filterOptions={filterOptions}
                    getOptionLabel={option => `${option.name} (${option.atc}) - ${getInjectionMethodName(option.method, intl)}`}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label={intl.formatMessage({
                          id: 'amrConsumptionInputScreen.addDrug',
                          defaultMessage: 'Выберите препарат чтобы добавить',
                          description: 'Add drug, input field label'
                        })}
                        variant='standard'
                        fullWidth
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password' // disable autocomplete and autofill
                        }}
                      />
                    )}
                    value={addDrugValue}
                    onChange={(event, value) => {
                      if (value !== null) {
                        setSelectedDrugs([...selectedDrugs, {
                          drug: value,
                          consumption: new Decimal(0),
                          is_valid: true,
                          current_string: ''
                        }])
                        setAddDrugValue(null)
                        setAddDrugInputValue('')
                      }
                    }}
                    inputValue={addDrugInputValue}
                    onInputChange={(event, value) => {
                      setAddDrugInputValue(value)
                    }}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Dialog open={pasteDialogOpen} maxWidth='lg'>
        <DialogTitle>
          <FormattedMessage
            id='amrConsumptionInputScreen.pasteDialogTitle'
            defaultMessage='Вставка препаратов из буфера обмена'
            description='Paste data for input dialog title'
          />
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            <FormattedMessage
              id='amrConsumptionInputScreen.pasteDialogDescription'
              defaultMessage='Выполнили поиск по каждой строке в буфере обмена. Убедитесь, что каждая строка была распознана правильно. Выберите "Игнорировать", если строку надо пропустить. Если препарат не был найден автоматически, выберите "Другой препарат". Уже добавленные препараты исключатются из поиска.'
              description='Paste data for input dialog description'
            />
          </DialogContentText>
          {pasteDialogValue.map((row, index) => (
            <Box key={index} mt={1}>
              <Typography variant='h6'>
                {row.inputString}
              </Typography>
              <RadioGroup
                name={`pasteDialogRadioGroup_${index}`}
                value={row.selectedItem}
                onChange={e => {
                  setPasteDialogValue([...pasteDialogValue.slice(0, index), { ...pasteDialogValue[index], selectedItem: Number(e.target.value) }, ...pasteDialogValue.slice(index + 1)])
                }}
              >
                {row.searchResult.map((option, index) => (
                  <FormControlLabel
                    key={index}
                    value={index}
                    control={<Radio size='small' />}
                    label={`${option.item.name} (${option.item.atc}) - ${getInjectionMethodName(option.item.method, intl)}`}
                  />
                ))}
                <FormControlLabel
                  value={-1}
                  control={<Radio size='small' />}
                  label={intl.formatMessage({
                    id: 'amrConsumptionInputScreen.pasteDialog.ignore',
                    defaultMessage: 'Игнорировать',
                    description: 'Ignore'
                  })}
                />
                <FormControlLabel
                  value={-2}
                  control={<Radio size='small' />}
                  label={intl.formatMessage({
                    id: 'amrConsumptionInputScreen.pasteDialog.anotherDrug',
                    defaultMessage: 'Другой препарат',
                    description: 'Another drug'
                  })}
                />

              </RadioGroup>
              {row.selectedItem === -2 && (
                <Box mt={1}>
                  <Autocomplete
                    size='small'
                    id='select-drugs'
                    options={// Filter out drags which are already selected
                    drugs.drugs.filter(drug => !selectedDrugs.some(selectedDrug => selectedDrug.drug.id === drug.id))
                  }
                    autoHighlight
                    filterOptions={filterOptions}
                    getOptionLabel={option => `${option.name} (${option.atc}) - ${getInjectionMethodName(option.method, intl)}`}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label={intl.formatMessage({
                          id: 'amrConsumptionInputScreen.addDrug',
                          defaultMessage: 'Выберите препарат чтобы добавить',
                          description: 'Add drug, input field label'
                        })}
                        variant='standard'
                        fullWidth
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password' // disable autocomplete and autofill
                        }}
                      />
                    )}
                    value={row.drugOverride}
                    onChange={(event, value) => {
                      setPasteDialogValue([...pasteDialogValue.slice(0, index), { ...pasteDialogValue[index], drugOverride: value }, ...pasteDialogValue.slice(index + 1)])
                    }}
                  />
                </Box>
              )}
            </Box>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPasteDialogOpen(false)}>
            <FormattedMessage
              id='amrConsumptionInputScreen.pasteDialog.cancel'

              defaultMessage='Отмена'
              description='Cancel button'
            />
          </Button>
          <Button onClick={() => {
            setPasteDialogOpen(false)
            const drugsToAdd: ISelectedDrugData[] = []
            // TODO: Add drugs to selectedDrugs
            pasteDialogValue.filter(row => row.selectedItem !== -1).forEach(row => {
              console.log(row)
              if (row.selectedItem === -2) {
                if (row.drugOverride !== null) {
                  if (selectedDrugs.some(selectedDrug => selectedDrug.drug.id === row.drugOverride?.id)) {
                    return
                  }
                  drugsToAdd.push({
                    drug: row.drugOverride,
                    consumption: row.inputConsumption.isNaN() ? new Decimal(0) : row.inputConsumption,
                    is_valid: true,
                    current_string: row.inputConsumption.isNaN() ? '' : row.inputConsumption.toString()
                  })
                }
              } else if (row.selectedItem >= 0) {
                console.log(row.searchResult[row.selectedItem])
                if (selectedDrugs.some(selectedDrug => selectedDrug.drug.id === row.searchResult[row.selectedItem].item.id || drugsToAdd.some(drugToAdd => drugToAdd.drug.id === row.searchResult[row.selectedItem].item.id))) {
                  console.log('Drug already selected')
                  return
                }
                drugsToAdd.push({
                  drug: row.searchResult[row.selectedItem].item,
                  consumption: row.inputConsumption.isNaN() ? new Decimal(0) : row.inputConsumption,
                  is_valid: true,
                  current_string: row.inputConsumption.isNaN() ? '' : row.inputConsumption.toString()
                })
              }
              setSelectedDrugs([...selectedDrugs, ...drugsToAdd])
            })
          }}
          >
            <FormattedMessage
              id='amrConsumptionInputScreen.pasteDialog.add'
              defaultMessage='Добавить'
              description='Add button'
            />
          </Button>
        </DialogActions>

      </Dialog>
    </Grid>
  )
}
