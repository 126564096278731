import React from 'react'
import { useMutation, useQuery } from 'react-query'
import { FormattedMessage, useIntl } from 'react-intl'
import { useAxios } from '../providers/AxiosProvider'
import { useParams } from 'react-router-dom'
import { IDrug, IDrugs, InjectionMethod } from '../interfaces/drugs'
import { Alert, AlertColor, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Fab, Grid, ListItemButton, MenuItem, Select, Snackbar, TextField, Tooltip, Typography } from '@mui/material'
import { AddOutlined, DeleteOutlined, FileDownloadOutlined, FileUploadOutlined, SaveOutlined, UnfoldLessOutlined, UnfoldMoreOutlined } from '@mui/icons-material'
import Decimal from 'decimal.js'
import { getInjectionMethodName } from '../utils/getInjectionMethodName'
import { exportDrugsToTable, importDrugsFromExcel } from '../utils/exportDrugs'

export default function Locale () {
  const params = useParams()
  const axios = useAxios()

  const [drugsData, setDrugsData] = React.useState<IDrug[] | undefined>(undefined)
  const [dense, setDense] = React.useState(false)
  const [openNewDrugDialog, setOpenNewDrugDialog] = React.useState(false)

  const [isAlertOpen, setIsAlertOpen] = React.useState(false)
  const [alertMessage, setAlertMessage] = React.useState('')
  const [alertSeverity, setAlertSeverity] = React.useState<AlertColor>('info')

  const drugs = useQuery<IDrugs, Error>(['drugs', params.id], async () => {
    const { data } = await axios.get(`/drugs/${params.id}`)
    return data
  }, {
    onSuccess: (data) => {
      if (data !== undefined) {
        if (data.drugs !== undefined) {
          if (drugsData === undefined) {
            console.log('setting drugs data')
            setDrugsData(data.drugs)
          }
        }
      }
    }
  })

  const commitUpdate = useMutation(
    async (payload: IDrug[]) => await axios.post(`/drugs/${params.id}`, payload), {
      onSuccess: async () => {
        await drugs.refetch()
        setAlertSeverity('success')
        setAlertMessage('Успешно сохранено')
        setIsAlertOpen(true)
      },
      onError: async (error) => {
        setAlertSeverity('error')
        setAlertMessage('Ошибка сохранения')
        setIsAlertOpen(true)
        console.error(error)
      }
    })

  const uploadData = (data: IDrug[]): void => {
    // data.forEach(drug => delete drug.id)
    commitUpdate.mutate(data)
  }

  const deleteElement = (index: number): void => {
    if (drugsData === undefined) {
      return
    }
    const newDrugs = [...drugsData]
    newDrugs.splice(index, 1)
    setDrugsData(newDrugs)
  }

  const updateElement = (index: number, drug: IDrug): void => {
    if (drugsData === undefined) {
      return
    }
    const newDrugs = [...drugsData]
    newDrugs[index] = drug
    setDrugsData(newDrugs)
  }

  if (drugsData === undefined) {
    return <div>Loading...</div>
  }

  const handleDataImport = async (file: File) => {
    setAlertSeverity('info')
    setAlertMessage('Импорт начат, подождите...')
    setIsAlertOpen(true)
    const data = await importDrugsFromExcel(file).catch(error => {
      setAlertSeverity('error')
      setAlertMessage('Ошибка импорта. Текущие данные не изменены.')
      setIsAlertOpen(true)
      console.error(error)
    })
    console.log(data)
    if (data === undefined) {
      setAlertSeverity('error')
      setAlertMessage('Ошибка импорта. Текущие данные не изменены.')
      setIsAlertOpen(true)
      return
    }
    setDrugsData(data)
    setAlertSeverity('success')
    setAlertMessage('Импорт завершён')
    setIsAlertOpen(true)
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {drugsData.map((drug, index) =>
          <DrugListItem
            index={index}
            key={`drug-${index}`}
            drug={drug}
            deleteCallback={deleteElement}
            updateCallback={updateElement}
            dense={dense}
            isDuplicate={drugsData.filter(d => d.name === drug.name && d.atc === drug.atc && d.method === drug.method).length > 1}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'end',
          position: 'fixed',
          bottom: '1rem',
          right: '1rem'
        }}
        >
          <Box sx={{
            display: 'flex',
            flexDirection: 'row'
          }}
          >
            <Tooltip title='Экспортировать в Excel файл' placement='top'>
              <Fab
                sx={{ mb: 2, mr: 1 }}
                color='default'
                size='small'
                onClick={() => exportDrugsToTable(drugsData, `amr_calc_${params.id}_${new Date(Date.now()).toISOString()}`)}
              >
                <FileDownloadOutlined />
              </Fab>
            </Tooltip>
            <Tooltip title='Импортировать из Excel файла' placement='top'>
              <label htmlFor='import-file'>
                <input
                  accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel' id='import-file' type='file' onChange={(e) => {
                    const file = e.target.files?.[0]
                    if (file != null) {
                      handleDataImport(file)
                    }
                  }} style={{ display: 'none' }}
                />
                <Fab
                  sx={{ mb: 2, mr: 1 }}
                  color='default'
                  size='small'
                  component='span'
                >
                  <FileUploadOutlined />
                </Fab>
              </label>
            </Tooltip>
            <Tooltip title={dense ? 'Развернуть строки' : 'Свернуть строки'} placement='top'>
              <Fab
                sx={{ mb: 2 }}
                color='default'
                size='small'
                onClick={() => setDense(!dense)}
              >
                {dense ? <UnfoldMoreOutlined /> : <UnfoldLessOutlined />}
              </Fab>
            </Tooltip>
          </Box>
          <Fab
            sx={{ mb: 2 }}
            color='default'
            variant='extended'
            onClick={() => setOpenNewDrugDialog(true)}
          >
            <AddOutlined sx={{ mr: 1 }} />
            <FormattedMessage id='drugsEditor.add' defaultMessage='Добавить' />
          </Fab>
          <Fab
            color='primary'
            variant='extended'
            onClick={() => uploadData(drugsData)}
            disabled={commitUpdate.isLoading}
          >
            <SaveOutlined sx={{ mr: 1 }} />
            <FormattedMessage id='drugsEditor.save' defaultMessage='Сохранить' />
          </Fab>
        </Box>
      </Grid>
      <DrugEditDialog
        open={openNewDrugDialog}
        drug={{
          id: undefined,
          name: '',
          method: InjectionMethod.Parenteral,
          atc: '',
          ddd_adult: '',
          ddd_child: '',
          ddd_1: '',
          ddd_2: '',
          ddd_3: '',
          ddd_4: '',
          ddd_5: '',
          ddd_6: '',
          ddd_7: '',
          ddd_8: '',
          ddd_9: '',
          ddd_10: '',
          ddd_11: '',
          ddd_12: ''
        }}
        closeCallback={() => setOpenNewDrugDialog(false)}
        saveCallback={(drug) => {
          if (drugsData === undefined) {
            return
          }
          const newDrugs = [...drugsData]
          newDrugs.push(drug)
          setDrugsData(newDrugs)
          setOpenNewDrugDialog(false)
        }}
        deleteCallback={undefined}
      />
      <Snackbar
        open={isAlertOpen}
        autoHideDuration={6000}
        onClose={() => setIsAlertOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert variant='filled' onClose={() => setIsAlertOpen(false)} severity={alertSeverity}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </Grid>
  )
}

interface DrugListItemProps {
  index: number
  drug: IDrug
  deleteCallback: (index: number) => void
  updateCallback: (index: number, drug: IDrug) => void
  dense: boolean
  isDuplicate: boolean
}

const DrugListItem = ({ index, drug, deleteCallback, updateCallback, dense, isDuplicate }: DrugListItemProps): JSX.Element => {
  const [isEditing, setIsEditing] = React.useState(false)

  const intl = useIntl()

  const handleEdit = (): void => {
    setIsEditing(true)
  }

  const handleCancel = (): void => {
    setIsEditing(false)
  }

  const handleSave = (newDrug: IDrug): void => {
    updateCallback(index, newDrug)
    setIsEditing(false)
  }

  const handleDelete = (): void => {
    deleteCallback(index)
    setIsEditing(false)
  }

  return (
    <>
      <ListItemButton
        sx={{ width: '100%' }} divider onClick={
      handleEdit
    }
      >
        <Grid container spacing={1}>
          {isDuplicate && <Grid item xs={12}>
            <Typography variant='caption' color='error'>
              <FormattedMessage id='drugsEditor.duplicate' defaultMessage='Этот препарат повторяется. Устраните конфликт перед сохранением.' />
            </Typography>
                          </Grid>}
          <Grid item xs={12} sm={4}>
            {!dense &&
              <Typography variant='overline'>
                <FormattedMessage id='drugsEditor.name' defaultMessage='МНН' />
              </Typography>}
            <Typography variant={dense ? 'body1' : 'h6'}>
              {drug.name}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            {!dense &&
              <Typography variant='overline'>
                <FormattedMessage id='drugsEditor.method' defaultMessage='Путь введения' />
              </Typography>}
            <Typography variant={dense ? 'body1' : 'h6'}>
              {getInjectionMethodName(drug.method, intl)}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            {!dense &&
              <Typography variant='overline'>
                <FormattedMessage id='drugsEditor.atc' defaultMessage='Код ATC' />
              </Typography>}
            <Typography variant={dense ? 'body1' : 'h6'}>
              {drug.atc}
            </Typography>
          </Grid>

          {!dense &&
            <>
              <Grid item xs={12} sm={4} md={2} lg={1}>
                <Typography variant='overline'>
                  <FormattedMessage id='drugsEditor.ddd_adult' defaultMessage='ДДД взрослые' />
                </Typography>
                <Typography variant='body2'>
                  {drug.ddd_adult}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={2} lg={1}>
                <Typography variant='overline'>
                  <FormattedMessage id='drugsEditor.ddd_child' defaultMessage='До 1 года' />
                </Typography>
                <Typography variant='body2'>
                  {drug.ddd_child}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={2} lg={1}>
                <Typography variant='overline'>
                  <FormattedMessage id='drugsEditor.ddd_1' defaultMessage='1 год' />
                </Typography>
                <Typography variant='body2'>
                  {drug.ddd_1}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={2} lg={1}>
                <Typography variant='overline'>
                  <FormattedMessage id='drugsEditor.ddd_2' defaultMessage='2 года' />
                </Typography>
                <Typography variant='body2'>
                  {drug.ddd_2}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={2} lg={1}>
                <Typography variant='overline'>
                  <FormattedMessage id='drugsEditor.ddd_3' defaultMessage='1 года' />
                </Typography>
                <Typography variant='body2'>
                  {drug.ddd_3}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={2} lg={1}>
                <Typography variant='overline'>
                  <FormattedMessage id='drugsEditor.ddd_4' defaultMessage='4 года' />
                </Typography>
                <Typography variant='body2'>
                  {drug.ddd_4}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={2} lg={1}>
                <Typography variant='overline'>
                  <FormattedMessage id='drugsEditor.ddd_5' defaultMessage='5 лет' />
                </Typography>
                <Typography variant='body2'>
                  {drug.ddd_5}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={2} lg={1}>
                <Typography variant='overline'>
                  <FormattedMessage id='drugsEditor.ddd_6' defaultMessage='6 лет' />
                </Typography>
                <Typography variant='body2'>
                  {drug.ddd_6}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={2} lg={1}>
                <Typography variant='overline'>
                  <FormattedMessage id='drugsEditor.ddd_7' defaultMessage='7 лет' />
                </Typography>
                <Typography variant='body2'>
                  {drug.ddd_7}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={2} lg={1}>
                <Typography variant='overline'>
                  <FormattedMessage id='drugsEditor.ddd_8' defaultMessage='8 лет' />
                </Typography>
                <Typography variant='body2'>
                  {drug.ddd_8}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={2} lg={1}>
                <Typography variant='overline'>
                  <FormattedMessage id='drugsEditor.ddd_9' defaultMessage='9 лет' />
                </Typography>
                <Typography variant='body2'>
                  {drug.ddd_9}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={2} lg={1}>
                <Typography variant='overline'>
                  <FormattedMessage id='drugsEditor.ddd_10' defaultMessage='10 лет' />
                </Typography>
                <Typography variant='body2'>
                  {drug.ddd_10}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={2} lg={1}>
                <Typography variant='overline'>
                  <FormattedMessage id='drugsEditor.ddd_11' defaultMessage='11 лет' />
                </Typography>
                <Typography variant='body2'>
                  {drug.ddd_11}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={2} lg={1}>
                <Typography variant='overline'>
                  <FormattedMessage id='drugsEditor.ddd_12' defaultMessage='12 лет' />
                </Typography>
                <Typography variant='body2'>
                  {drug.ddd_12}
                </Typography>
              </Grid>
            </>}

        </Grid>
      </ListItemButton>
      <DrugEditDialog
        open={isEditing}
        drug={drug}
        deleteCallback={handleDelete}
        saveCallback={(newDrug: IDrug) => handleSave(newDrug)}
        closeCallback={handleCancel}
      />
    </>
  )
}

interface DrugEditDialogProps {
  open: boolean
  drug: IDrug
  deleteCallback: undefined | (() => void)
  saveCallback: (drug: IDrug) => void
  closeCallback: () => void
}

const isValidDecimal = (value: string): boolean => {
  if (value === '') {
    return true
  }
  try {
    const decimal = new Decimal(value)
    return decimal.isFinite()
  } catch (DecimalError) {
    return false
  }
}

function DrugEditDialog ({ open, drug, deleteCallback, saveCallback, closeCallback }: DrugEditDialogProps): JSX.Element {
  const [name, setName] = React.useState(drug.name)
  const [injectionMethod, setInjectionMethod] = React.useState(drug.method)
  const [atc, setAtc] = React.useState(drug.atc)
  const [dddAdult, setDddAdult] = React.useState(drug.ddd_adult)
  const [dddChild, setDddChild] = React.useState(drug.ddd_child)
  const [ddd1, setDdd1] = React.useState(drug.ddd_1)
  const [ddd2, setDdd2] = React.useState(drug.ddd_2)
  const [ddd3, setDdd3] = React.useState(drug.ddd_3)
  const [ddd4, setDdd4] = React.useState(drug.ddd_4)
  const [ddd5, setDdd5] = React.useState(drug.ddd_5)
  const [ddd6, setDdd6] = React.useState(drug.ddd_6)
  const [ddd7, setDdd7] = React.useState(drug.ddd_7)
  const [ddd8, setDdd8] = React.useState(drug.ddd_8)
  const [ddd9, setDdd9] = React.useState(drug.ddd_9)
  const [ddd10, setDdd10] = React.useState(drug.ddd_10)
  const [ddd11, setDdd11] = React.useState(drug.ddd_11)
  const [ddd12, setDdd12] = React.useState(drug.ddd_12)

  const intl = useIntl()

  const isValid = (): boolean => {
    return isValidDecimal(dddAdult) && isValidDecimal(dddChild) && isValidDecimal(ddd1) && isValidDecimal(ddd2) && isValidDecimal(ddd3) && isValidDecimal(ddd4) && isValidDecimal(ddd5) && isValidDecimal(ddd6) && isValidDecimal(ddd7) && isValidDecimal(ddd8) && isValidDecimal(ddd9) && isValidDecimal(ddd10) && isValidDecimal(ddd11) && isValidDecimal(ddd12) && name !== '' && atc !== ''
  }

  const handleSave = (): void => {
    const newDrug = {
      id: drug.id,
      name: name.trim(),
      method: injectionMethod,
      atc: atc.trim(),
      ddd_adult: dddAdult === '' ? '0.0' : dddAdult,
      ddd_child: dddChild === '' ? '0.0' : dddChild,
      ddd_1: ddd1 === '' ? '0.0' : ddd1,
      ddd_2: ddd2 === '' ? '0.0' : ddd2,
      ddd_3: ddd3 === '' ? '0.0' : ddd3,
      ddd_4: ddd4 === '' ? '0.0' : ddd4,
      ddd_5: ddd5 === '' ? '0.0' : ddd5,
      ddd_6: ddd6 === '' ? '0.0' : ddd6,
      ddd_7: ddd7 === '' ? '0.0' : ddd7,
      ddd_8: ddd8 === '' ? '0.0' : ddd8,
      ddd_9: ddd9 === '' ? '0.0' : ddd9,
      ddd_10: ddd10 === '' ? '0.0' : ddd10,
      ddd_11: ddd11 === '' ? '0.0' : ddd11,
      ddd_12: ddd12 === '' ? '0.0' : ddd12
    }
    saveCallback(newDrug)
  }

  return (
    <Dialog open={open} onClose={closeCallback}>
      <DialogTitle>
        <FormattedMessage id='drugsEditor.edit' defaultMessage='Редактирование лекарства' />
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant='standard'
              label={<FormattedMessage id='drugsEditor.name' defaultMessage='МНН' />}
              value={name}
              onChange={(e) => {
                setName(e.target.value)
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              value={Object.keys(InjectionMethod)[Object.values(InjectionMethod).indexOf(injectionMethod)]}
              variant='standard'
              onChange={(e) => {
                setInjectionMethod(InjectionMethod[e.target.value as keyof typeof InjectionMethod])
              }}
              style={{ width: '100%' }}
            >
              {Object.keys(InjectionMethod).filter(
                (key) => InjectionMethod[key as keyof typeof InjectionMethod] !== InjectionMethod.Combined
              ).map((key) => (
                <MenuItem key={key} value={key}>
                  {getInjectionMethodName(InjectionMethod[key as keyof typeof InjectionMethod], intl)}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant='standard'
              label={<FormattedMessage id='drugsEditor.atc' defaultMessage='Код ATC' />}
              value={atc}
              onChange={(e) => {
                setAtc(e.target.value.substring(0, 7).toUpperCase())
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant='standard'
              label={<FormattedMessage id='drugsEditor.ddd_adult' defaultMessage='ДДД взрослые' />}
              value={dddAdult}
              onChange={(e) => setDddAdult(e.target.value.replace(',', '.'))}
              error={!isValidDecimal(drug.ddd_adult)}
              helperText={!isValidDecimal(drug.ddd_adult) ? 'Неверное значение' : ''}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant='standard'
              label={<FormattedMessage id='drugsEditor.ddd_child' defaultMessage='До 1 года' />}
              value={dddChild}
              onChange={(e) => setDddChild(e.target.value.replace(',', '.'))}
              error={!isValidDecimal(drug.ddd_child)}
              helperText={!isValidDecimal(drug.ddd_child) ? 'Неверное значение' : ''}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant='standard'
              label={<FormattedMessage id='drugsEditor.ddd_1' defaultMessage='1 год' />}
              value={ddd1}
              onChange={(e) => setDdd1(e.target.value.replace(',', '.'))}
              error={!isValidDecimal(ddd1)}
              helperText={!isValidDecimal(ddd1) ? 'Неверное значение' : ''}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant='standard'
              label={<FormattedMessage id='drugsEditor.ddd_2' defaultMessage='2 года' />}
              value={ddd2}
              onChange={(e) => setDdd2(e.target.value.replace(',', '.'))}
              error={!isValidDecimal(ddd2)}
              helperText={!isValidDecimal(ddd2) ? 'Неверное значение' : ''}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant='standard'
              label={<FormattedMessage id='drugsEditor.ddd_3' defaultMessage='3 года' />}
              value={ddd3}
              onChange={(e) => setDdd3(e.target.value.replace(',', '.'))}
              error={!isValidDecimal(ddd3)}
              helperText={!isValidDecimal(ddd3) ? 'Неверное значение' : ''}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant='standard'
              label={<FormattedMessage id='drugsEditor.ddd_4' defaultMessage='4 года' />}
              value={ddd4}
              onChange={(e) => setDdd4(e.target.value.replace(',', '.'))}
              error={!isValidDecimal(ddd4)}
              helperText={!isValidDecimal(ddd4) ? 'Неверное значение' : ''}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant='standard'
              label={<FormattedMessage id='drugsEditor.ddd_5' defaultMessage='5 лет' />}
              value={ddd5}
              onChange={(e) => setDdd5(e.target.value.replace(',', '.'))}
              error={!isValidDecimal(ddd5)}
              helperText={!isValidDecimal(ddd5) ? 'Неверное значение' : ''}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant='standard'
              label={<FormattedMessage id='drugsEditor.ddd_6' defaultMessage='6 лет' />}
              value={ddd6}
              onChange={(e) => setDdd6(e.target.value.replace(',', '.'))}
              error={!isValidDecimal(ddd6)}
              helperText={!isValidDecimal(ddd6) ? 'Неверное значение' : ''}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant='standard'
              label={<FormattedMessage id='drugsEditor.ddd_7' defaultMessage='7 лет' />}
              value={ddd7}
              onChange={(e) => setDdd7(e.target.value.replace(',', '.'))}
              error={!isValidDecimal(ddd7)}
              helperText={!isValidDecimal(ddd7) ? 'Неверное значение' : ''}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant='standard'
              label={<FormattedMessage id='drugsEditor.ddd_8' defaultMessage='8 лет' />}
              value={ddd8}
              onChange={(e) => setDdd8(e.target.value.replace(',', '.'))}
              error={!isValidDecimal(ddd8)}
              helperText={!isValidDecimal(ddd8) ? 'Неверное значение' : ''}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant='standard'
              label={<FormattedMessage id='drugsEditor.ddd_9' defaultMessage='9 лет' />}
              value={ddd9}
              onChange={(e) => setDdd9(e.target.value.replace(',', '.'))}
              error={!isValidDecimal(ddd9)}
              helperText={!isValidDecimal(ddd9) ? 'Неверное значение' : ''}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant='standard'
              label={<FormattedMessage id='drugsEditor.ddd_10' defaultMessage='10 лет' />}
              value={ddd10}
              onChange={(e) => setDdd10(e.target.value.replace(',', '.'))}
              error={!isValidDecimal(ddd10)}
              helperText={!isValidDecimal(ddd10) ? 'Неверное значение' : ''}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant='standard'
              label={<FormattedMessage id='drugsEditor.ddd_11' defaultMessage='11 лет' />}
              value={ddd11}
              onChange={(e) => setDdd11(e.target.value.replace(',', '.'))}
              error={!isValidDecimal(ddd11)}
              helperText={!isValidDecimal(ddd11) ? 'Неверное значение' : ''}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant='standard'
              label={<FormattedMessage id='drugsEditor.ddd_12' defaultMessage='12 лет' />}
              value={ddd12}
              onChange={(e) => setDdd12(e.target.value.replace(',', '.'))}
              error={!isValidDecimal(ddd12)}
              helperText={!isValidDecimal(ddd12) ? 'Неверное значение' : ''}
            />
          </Grid>
        </Grid>

      </DialogContent>
      <DialogActions>
        {deleteCallback !== undefined &&
          <Button
            startIcon={<DeleteOutlined />}
            onClick={deleteCallback}
            color='error'
          >
            <FormattedMessage id='drugsEditor.delete' defaultMessage='Удалить' />
          </Button>}
        <Box sx={{ flexGrow: 1 }} />
        <Button onClick={closeCallback} color='primary'>
          <FormattedMessage id='drugsEditor.cancel' defaultMessage='Отмена' />
        </Button>
        <Button
          onClick={handleSave}
          color='primary'
          disabled={!isValid()}
        >
          <FormattedMessage id='drugsEditor.save' defaultMessage='Сохранить' />
        </Button>
      </DialogActions>
    </Dialog>
  )
}
