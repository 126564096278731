import * as React from 'react'
import {
  QueryClient,
  QueryClientProvider
} from 'react-query'
import { Routes, Route } from 'react-router-dom'
import { ReactQueryDevtools } from 'react-query/devtools'
import { isLoggedIn } from 'axios-jwt'
import Login from './screens/Login'
import { logOut } from './providers/AuthProvider'
import Locales from './screens/Locales'
import Layout from './organisms/Layout'
import Locale from './screens/Locale'
import Calculator from './screens/Calculator'
import { IntlProvider } from 'react-intl'
import messagesRu from './compiled-lang/ru.json'
import messagesEn from './compiled-lang/en.json'

const queryClient = new QueryClient()

export default function App (): JSX.Element {
  const [loginState, setLoginState] = React.useState(isLoggedIn())
  const logOutHandler = (): void => {
    logOut()
    queryClient.removeQueries()
    setLoginState(false)
  }
  const checkLogInStatus = (): void => {
    setLoginState(
      isLoggedIn()
    )
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [locale, setLocale] = React.useState('ru')

  const messages = React.useMemo(
    () => {
      switch (locale) {
        case 'ru':
          return messagesRu
        case 'en':
          return messagesEn
        default:
          return messagesRu
      }
    }, [locale])

  return (
    <QueryClientProvider client={queryClient}>
      <IntlProvider
        locale={locale}
        messages={messages}
      >
        <Layout handleLogOut={logOutHandler} locale={locale} setLocale={setLocale}>
          <Routes>
            <Route path='/' element={<Calculator locale={locale} />} />
            {loginState
              ? <>
                <Route path='admin/locales/:id' element={<Locale />} />
                <Route path='admin/locales' element={<Locales />} />
                <Route path='admin' element={<Locales />} />
                </>
              : <Route path='admin' element={<Login setLoginState={checkLogInStatus} />} />}
          </Routes>
        </Layout>
      </IntlProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}
