import { WorkBook, WorkSheet, writeFile, utils, read as readXlsx } from 'xlsx'
import { IDrug } from '../interfaces/drugs'

// Export data as a .xlsx or .ods file.
// First sheet contains basic metadata with the app version and date of export
// Second sheet contains the age groups
// Third sheet contains the data
// Also may export to csv, but will only export the data
export function exportDrugsToTable (
  drugs: IDrug[],
  fileName: string
): void {
  const drugsSheet: WorkSheet = utils.aoa_to_sheet([
    [
      'ID (не изменять)',
      'МНН',
      'Путь введения',
      'ATC',
      'ДДД взрослые',
      'До 1 года',
      '1 год',
      '2 года',
      '3 года',
      '4 года',
      '5 лет',
      '6 лет',
      '7 лет',
      '8 лет',
      '9 лет',
      '10 лет',
      '11 лет',
      '12 лет'
    ],
    ...drugs.map(drug => [
      drug.id,
      drug.name,
      drug.method,
      drug.atc,
      drug.ddd_adult,
      drug.ddd_child,
      drug.ddd_1,
      drug.ddd_2,
      drug.ddd_3,
      drug.ddd_4,
      drug.ddd_5,
      drug.ddd_6,
      drug.ddd_7,
      drug.ddd_8,
      drug.ddd_9,
      drug.ddd_10,
      drug.ddd_11,
      drug.ddd_12
    ])
  ])
  drugsSheet['!cols'] = [
    { wch: 10 },
    { wch: 50 },
    { wch: 30 },
    { wch: 30 },
    { wch: 30 },
    { wch: 30 },
    { wch: 30 },
    { wch: 30 },
    { wch: 30 },
    { wch: 30 },
    { wch: 30 },
    { wch: 30 },
    { wch: 30 },
    { wch: 30 },
    { wch: 30 },
    { wch: 30 },
    { wch: 30 },
    { wch: 30 }
  ]

  const wb: WorkBook = {
    SheetNames: ['Drugs'],
    Sheets: {
      Drugs: drugsSheet
    }
  }
  writeFile(wb, `${fileName}.xlsx`, { bookType: 'xlsx' })
}

export async function importDrugsFromExcel (file: File): Promise<IDrug[]> {
  return await new Promise((resolve, reject) => {
    const reader: FileReader = new FileReader()
    reader.onload = (e) => {
      if (e.target === null) {
        return
      }
      try {
        const data = e.target.result
        const wb: WorkBook = readXlsx(data)
        const drugs: WorkSheet = wb.Sheets.Drugs

        const drugsData: IDrug[] = []
        console.log(drugs)
        console.log(drugs['!ref']?.split(':')[1].substring(1))
        for (let i = 2; i <= Number(drugs['!ref']?.split(':')[1].substring(1)) ?? 0; i++) {
          try {
            const drug: IDrug = {
              id: drugs[`A${i}`].v,
              name: drugs[`B${i}`].v,
              method: drugs[`C${i}`].v,
              atc: drugs[`D${i}`].v.toString().trim().toUpperCase(),
              ddd_adult: drugs[`E${i}`].v.toString().replace(',', '.'),
              ddd_child: drugs[`F${i}`].v.toString().replace(',', '.'),
              ddd_1: drugs[`G${i}`].v.toString().replace(',', '.'),
              ddd_2: drugs[`H${i}`].v.toString().replace(',', '.'),
              ddd_3: drugs[`I${i}`].v.toString().replace(',', '.'),
              ddd_4: drugs[`J${i}`].v.toString().replace(',', '.'),
              ddd_5: drugs[`K${i}`].v.toString().replace(',', '.'),
              ddd_6: drugs[`L${i}`].v.toString().replace(',', '.'),
              ddd_7: drugs[`M${i}`].v.toString().replace(',', '.'),
              ddd_8: drugs[`N${i}`].v.toString().replace(',', '.'),
              ddd_9: drugs[`O${i}`].v.toString().replace(',', '.'),
              ddd_10: drugs[`P${i}`].v.toString().replace(',', '.'),
              ddd_11: drugs[`Q${i}`].v.toString().replace(',', '.'),
              ddd_12: drugs[`R${i}`].v.toString().replace(',', '.')
            }
            drugsData.push(drug)
            console.log('drug', drug)
          } catch (e) {
            console.log('error', e)
          }
        }
        console.log('resolve')
        resolve(drugsData)
      } catch (e) {
        reject(e)
      }
    }
    reader.readAsArrayBuffer(file)
  }
  )
}
