import * as React from 'react'
import ReactDOM from 'react-dom/client'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import App from './App'
import theme from './theme'
import AxiosProvider from './providers/AxiosProvider'
import { BrowserRouter as Router } from 'react-router-dom'
import { YMInitializer } from 'react-yandex-metrika'

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = ReactDOM.createRoot(document.querySelector('#root')!)

root.render(
  <ThemeProvider theme={theme}>
      <YMInitializer accounts={[92124179]} options={ { webvisor: true } } />
    <CssBaseline />
    <AxiosProvider>
      <Router>
        <App />
      </Router>
    </AxiosProvider>
  </ThemeProvider>
)
