import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Box, Grid, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Tabs, Tab, Select, MenuItem, Button, Menu } from '@mui/material'
import { InjectionMethod, IConsumption } from '../interfaces/drugs'
import { Bar } from 'react-chartjs-2'
import { getInjectionMethodName } from '../utils/getInjectionMethodName'
import { FileUploadOutlined } from '@mui/icons-material'

export function ResultsScreen ({
  groupedConsumptions,
  resultsTab,
  setResultsTab,
  groupByInjectionMethod,
  setGroupByInjectionMethod,
  atcGroupLevel,
  setAtcGroupLevel,
  exportCallback
}: {
  groupedConsumptions: IConsumption[]
  resultsTab: number
  setResultsTab: React.Dispatch<React.SetStateAction<number>>
  groupByInjectionMethod: boolean
  setGroupByInjectionMethod: React.Dispatch<React.SetStateAction<boolean>>
  atcGroupLevel: number
  setAtcGroupLevel: React.Dispatch<React.SetStateAction<number>>
  exportCallback: (fileType: 'xlsx' | 'ods' | 'csv') => void
}): JSX.Element {
  const [exportAnchor, setExportAnchor] = React.useState<HTMLElement | null>(null)
  const openExportMenu = Boolean(exportAnchor)

  const handleExportClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    setExportAnchor(event.currentTarget)
  }

  const handleExportClose = (): void => {
    setExportAnchor(null)
  }

  const intl = useIntl()
  const chartOptions = {
    indexAxis: 'y' as const,
    elements: {
      bar: {
        borderWidth: 2
      }
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'right' as const
      }
    }
  }

  const chartData = {
    labels: groupedConsumptions.map(group => {
      if (group.method === InjectionMethod.Combined) {
        return `${group.name} ${group.atc}`
      } else {
        return [
          `${group.name} ${group.atc}`,
          `${getInjectionMethodName(group.method, intl)}`
        ]
      }
    }),
    datasets: [
      {
        label: intl.formatMessage({ id: 'resultsScreen.childrenMethodology', defaultMessage: 'Детская методология' }),
        data: groupedConsumptions.map(group => group.childrenMethodologyResult.toNumber()),
        backgroundColor: '#005a51'
      },
      {
        label: intl.formatMessage({ id: 'resultsScreen.standardMethodology', defaultMessage: 'Стандартная методология' }),
        data: groupedConsumptions.map(group => group.standardMethodologyResult.toNumber()),
        backgroundColor: '#dc3546'
      }
    ]
  }

  return (
    <Grid mt={2} container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h6' display='inline'>
          <FormattedMessage
            id='resultsScreen.title'
            defaultMessage='Потребление, DDD/100 койко-дней'
            description='Title of the results screen'
          />
        </Typography>
        <div style={{ float: 'right' }}>
          <Button
            onClick={handleExportClick}
            startIcon={<FileUploadOutlined />}
          >
            <FormattedMessage id='calculator.export' defaultMessage='Экспорт' />
          </Button>
          <Menu
            open={openExportMenu}
            onClose={handleExportClose}
            anchorEl={exportAnchor}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
          >
            <MenuItem onClick={() => exportCallback('xlsx')}>
              <FormattedMessage id='calculator.exportXlsx' defaultMessage='Экспорт в XLSX' />
            </MenuItem>
            <MenuItem onClick={() => exportCallback('ods')}>
              <FormattedMessage id='calculator.exportOds' defaultMessage='Экспорт в ODS' />
            </MenuItem>
            <MenuItem onClick={() => exportCallback('csv')}>
              <FormattedMessage id='calculator.exportCsv' defaultMessage='Экспорт в CSV' />
            </MenuItem>
          </Menu>
        </div>
      </Grid>
      <Grid item xs={12} container spacing={3}>
        <Grid item xs={6}>
          <Select size='small' fullWidth value={groupByInjectionMethod.toString()} onChange={(e) => setGroupByInjectionMethod(e.target.value === 'true')}>
            <MenuItem value='false'>
              <FormattedMessage
                id='resultsScreen.groupByDrug.false'
                defaultMessage='Не группировать по способу введения'
                description='Group by injection method option'
              />
            </MenuItem>
            <MenuItem value='true'>
              <FormattedMessage
                id='resultsScreen.groupByDrug.true'
                defaultMessage='Группировать по способу введения'
                description='Group by injection method option'
              />
            </MenuItem>
          </Select>
        </Grid>
        <Grid item xs={6}>
          <Select size='small' fullWidth value={atcGroupLevel.toString()} onChange={(e) => setAtcGroupLevel(parseInt(e.target.value))}>
            <MenuItem value='2'>
              <FormattedMessage
                id='resultsScreen.groupByAtc.2'
                defaultMessage='2 уровень АТС (например, J01)'
                description='Group by ATC level option'
              />
            </MenuItem>
            <MenuItem value='3'>
              <FormattedMessage
                id='resultsScreen.groupByAtc.3'
                defaultMessage='3 уровень АТС (например, J01C)'
                description='Group by ATC level option'
              />
            </MenuItem>
            <MenuItem value='4'>
              <FormattedMessage
                id='resultsScreen.groupByAtc.4'
                defaultMessage='4 уровень АТС (например, J01CA)'
                description='Group by ATC level option'
              />
            </MenuItem>
            <MenuItem value='5'>
              <FormattedMessage
                id='resultsScreen.groupByAtc.5'
                defaultMessage='5 уровень АТС (например, J01CA01)'
                description='Group by ATC level option'
              />
            </MenuItem>
          </Select>
        </Grid>

      </Grid>
      <Grid item xs={12}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={resultsTab} onChange={(e, newValue) => setResultsTab(newValue)}>
            <Tab label='Таблицей' />
            <Tab label='Гистограммой' />
          </Tabs>
        </Box>
      </Grid>
      {resultsTab === 0 && (
        <Grid item xs={12}>
          <TableContainer>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <FormattedMessage
                      id='resultsScreen.tableHeaders.IDName'
                      defaultMessage='МНН'
                      description='Table header for International drug name'
                    />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage
                      id='resultsScreen.tableHeaders.childrenMethodology'
                      defaultMessage='Детская методология'
                      description='Table header for children methodology'
                    />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage
                      id='resultsScreen.tableHeaders.standardMethodology'
                      defaultMessage='Стандартная методология'
                      description='Table header for standard methodology'
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {groupedConsumptions.map((drug, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Typography variant='body2'>{`${drug.name} ${drug.atc}`}</Typography>
                      {drug.method !== InjectionMethod.Combined && (
                        <Typography variant='caption' color='textSecondary'>{getInjectionMethodName(drug.method, intl)}</Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      {groupedConsumptions[index].childrenMethodologyResult.toFixed(2)}
                    </TableCell>
                    <TableCell>
                      {groupedConsumptions[index].standardMethodologyResult.toFixed(2)}
                    </TableCell>

                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
      {resultsTab === 1 && (
        <Grid item xs={12}>
          <Bar options={chartOptions} data={chartData} />
        </Grid>
      )}
    </Grid>
  )
}
