import { IDrug, ISelectedDrugData } from '../interfaces/drugs'

export function refreshSelectedDrugs (drugs: IDrug[], selectedDrugs: ISelectedDrugData[]): ISelectedDrugData[] {
  /// Replaces all Drug objects of selectedDrugs with new drugs by their ID.
  /// If ID not found, the drug is deleted from selectedDrugs.

  // First we filter the drugs to delete the ones that are not in the list
  const filteredDrugs = selectedDrugs.filter(selectedDrug => {
    return drugs.find(drug => drug.id === selectedDrug.drug.id) !== undefined
  })
  // Then we replace the drugs
  const replacedDrugs = filteredDrugs.map(selectedDrug => {
    const newDrug = drugs.find(drug => drug.id === selectedDrug.drug.id)
    if (newDrug !== undefined) {
      return {
        ...selectedDrug,
        drug: newDrug
      }
    } else {
      return selectedDrug
    }
  })
  return replacedDrugs
}
