import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material'
import React from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useAxios } from '../providers/AxiosProvider'

export default function CreateLocaleDialog ({ open, onClose }: { open: boolean, onClose: () => void }): JSX.Element {
  const [title, setTitle] = React.useState('')

  const axios = useAxios()
  const queryClient = useQueryClient()

  interface ICreateLocale {
    name: string
  }

  const commitCreation = useMutation(
    async (payload: ICreateLocale) => await axios.post('/locales', payload), {
      onSuccess: async () => {
        await queryClient.invalidateQueries('locales')
        onClose()
      }
    }
  )

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    commitCreation.mutate({
      name: title
    })
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Create new locale</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent dividers>
          <Grid container spacing={2}>
            {commitCreation.isError &&
              <Grid item xs={12}>
                <Alert severity='error' title='Failed to add the record' />
              </Grid>}
            <Grid item xs={12}>
              <TextField
                id='title'
                label='Locale title'
                variant='outlined'
                fullWidth
                required
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type='submit' disabled={commitCreation.isLoading}>
            Create locale
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
