import Decimal from 'decimal.js'
import { WorkBook, WorkSheet, writeFile, utils, read as readXlsx } from 'xlsx'
import { IExportRow, IAgeGroups, IImportRow } from '../interfaces/drugs'
import ym from 'react-yandex-metrika'

// Export data as a .xlsx or .ods file.
// First sheet contains basic metadata with the app version and date of export
// Second sheet contains the age groups
// Third sheet contains the data
// Also may export to csv, but will only export the data

export function exportDataToTable (
  ageGroups: IAgeGroups,
  bedDays: Decimal,
  drugs: IExportRow[],
  appVersion: string,
  date: string,
  fileName: string,
  fileType: 'xlsx' | 'ods' | 'csv'
): void {
  const metadata: WorkSheet = utils.aoa_to_sheet([
    [
      'AMR Calc'
    ],
    [
      'Расчет уровня потребления антимикробных препаратов в стационарах с детскими отделениями'
    ],
    [],
    [
      'Версия приложения',
      appVersion
    ],
    [
      'Дата расчета',
      date
    ],
    [
      'Регион',
      'ru'
    ],
    [],
    [
      'Используемые обозначения путей введения'
    ],
    [
      'P',
      'парентерально'
    ],
    [
      'O',
      'перорально'
    ],
    [
      'N',
      'назально'
    ],
    [
      'R',
      'ректально'
    ],
    [
      'SL',
      'сублингвально'
    ],
    [
      'TD',
      'трансдермально'
    ],
    [
      'V',
      'интравагинально'
    ],
    [
      'Implant',
      'имплантат'
    ],
    [
      'Inhal',
      'ингаляционно'
    ],
    [
      'Instill',
      'инстилляция'
    ]
  ])
  metadata['!cols'] = [{ wch: 30 }, { wch: 30 }]

  const ageGroupsSheet: WorkSheet = utils.aoa_to_sheet([
    [
      'Возрастная группа',
      'Количество'
    ],
    [
      'Дети до 1 года',
      ageGroups.children.toNumber()
    ],
    [
      '1 год',
      ageGroups.age_1.toNumber()
    ],
    [
      '2 года',
      ageGroups.age_2.toNumber()
    ],
    [
      '3 года',
      ageGroups.age_3.toNumber()
    ],
    [
      '4 года',
      ageGroups.age_4.toNumber()
    ],
    [
      '5 лет',
      ageGroups.age_5.toNumber()
    ],
    [
      '6 лет',
      ageGroups.age_6.toNumber()
    ],
    [
      '7 лет',
      ageGroups.age_7.toNumber()
    ],
    [
      '8 лет',
      ageGroups.age_8.toNumber()
    ],
    [
      '9 лет',
      ageGroups.age_9.toNumber()
    ],
    [
      '10 лет',
      ageGroups.age_10.toNumber()
    ],
    [
      '11 лет',
      ageGroups.age_11.toNumber()
    ],
    [
      '12 лет',
      ageGroups.age_12.toNumber()
    ],
    [
      '13 лет и старше',
      ageGroups.adults.toNumber()
    ],
    [],
    [
      'Общее количество койко-дней в стационаре',
      bedDays.toNumber()
    ]
  ])
  ageGroupsSheet['!cols'] = [{ wch: 50 }, { wch: 30 }]

  const drugsSheet: WorkSheet = utils.aoa_to_sheet([
    [
      'МНН',
      'Путь введения',
      'ATC',
      'Потребление, г',
      'Потребление, детская методология',
      'Потребление, стандартная методология'
    ],
    ...drugs.map(drug => [
      drug.name,
      drug.method,
      drug.atc,
      drug.consumption.toNumber(),
      drug.childrenMethodologyResult.toNumber(),
      drug.standardMethodologyResult.toNumber()
    ])
  ])

  // Apply "0.000" format to the last three columns
  for (let i = 2; i <= drugs.length + 1; i++) {
    try {
      drugsSheet[`D${i}`].z = '0.000'
      drugsSheet[`E${i}`].z = '0.000'
      drugsSheet[`F${i}`].z = '0.000'
    } catch (e) {
      console.error(e)
    }
  }

  drugsSheet['!cols'] = [
    { wch: 50 },
    { wch: 30 },
    { wch: 30 },
    { wch: 30 },
    { wch: 30 },
    { wch: 30 }
  ]

  const wb: WorkBook = {
    SheetNames: ['Metadata', 'AgeGroups', 'Drugs'],
    Sheets: {
      Metadata: metadata,
      AgeGroups: ageGroupsSheet,
      Drugs: drugsSheet
    }
  }
  // Write to file: ods or xlsx depending on the user choice
  switch (fileType) {
    case 'ods':
      writeFile(wb, `${fileName}.ods`, { bookType: 'ods' })
      break
    case 'xlsx':
      writeFile(wb, `${fileName}.xlsx`, { bookType: 'xlsx' })
      break
    case 'csv':
      writeFile(wb, `${fileName}.csv`, { bookType: 'csv', sheet: 'Drugs' })
      break
    default:
      writeFile(wb, `${fileName}.xlsx`, { bookType: 'xlsx' })
      break
  }

  ym('reachGoal', 'export', {
    fileType
  })
}

export async function importDataFromExcel (file: File): Promise<{
  ageGroups: IAgeGroups
  bedDays: Decimal
  drugs: IImportRow[]
}> {
  return await new Promise((resolve, reject) => {
    const reader: FileReader = new FileReader()
    reader.onload = (e) => {
      if (e.target === null) {
        return
      }
      try {
        const data = e.target.result
        const wb: WorkBook = readXlsx(data)
        const ageGroups: WorkSheet = wb.Sheets.AgeGroups
        const drugs: WorkSheet = wb.Sheets.Drugs

        const ageGroupsData: IAgeGroups = {
          children: new Decimal(ageGroups.B2.v.toString().replace(',', '.')),
          age_1: new Decimal(ageGroups.B3.v.toString().replace(',', '.')),
          age_2: new Decimal(ageGroups.B4.v.toString().replace(',', '.')),
          age_3: new Decimal(ageGroups.B5.v.toString().replace(',', '.')),
          age_4: new Decimal(ageGroups.B6.v.toString().replace(',', '.')),
          age_5: new Decimal(ageGroups.B7.v.toString().replace(',', '.')),
          age_6: new Decimal(ageGroups.B8.v.toString().replace(',', '.')),
          age_7: new Decimal(ageGroups.B9.v.toString().replace(',', '.')),
          age_8: new Decimal(ageGroups.B10.v.toString().replace(',', '.')),
          age_9: new Decimal(ageGroups.B11.v.toString().replace(',', '.')),
          age_10: new Decimal(ageGroups.B12.v.toString().replace(',', '.')),
          age_11: new Decimal(ageGroups.B13.v.toString().replace(',', '.')),
          age_12: new Decimal(ageGroups.B14.v.toString().replace(',', '.')),
          adults: new Decimal(ageGroups.B15.v.toString().replace(',', '.'))
        }

        const bedDays = new Decimal(ageGroups.B17.v.toString().replace(',', '.'))

        const drugsData: IImportRow[] = []
        console.log(drugs)
        console.log(drugs['!ref']?.split(':')[1].substring(1))
        for (let i = 2; i <= Number(drugs['!ref']?.split(':')[1].substring(1)) ?? 0; i++) {
          try {
            const drug: IImportRow = {
              name: drugs[`A${i}`].v.toString().trim(),
              method: drugs[`B${i}`].v.toString().trim(),
              atc: drugs[`C${i}`].v.toString().trim(),
              consumption: new Decimal(drugs[`D${i}`].v.toString().replace(',', '.'))
            }
            drugsData.push(drug)
          } catch (e) {
            console.log(e)
          }
        }

        ym('reachGoal', 'import', {
          success: true
        })

        resolve({ ageGroups: ageGroupsData, bedDays, drugs: drugsData })
      } catch (e) {
        ym('reachGoal', 'import', {
          success: false
        })
        reject(e)
      }
    }
    reader.readAsArrayBuffer(file)
  }
  )
}
