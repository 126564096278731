import { Card, CardActionArea, CardHeader } from '@mui/material'
import React from 'react'
import { ILocale } from '../interfaces/locales'

function getLocaleName (locale: ILocale): string {
  switch (locale.name) {
    case 'ru':
      return 'Русский'
    case 'en':
      return 'English'
    default:
      return locale.name
  }
}

export default function LocaleCard ({ locale }: { locale: ILocale }): JSX.Element {
  return (
    <Card>
      <CardActionArea>
        <CardHeader title={getLocaleName(locale)} />
      </CardActionArea>
    </Card>
  )
}
