import { IntlShape } from 'react-intl'

import { InjectionMethod } from '../interfaces/drugs'

export function getInjectionMethodName (method: InjectionMethod, intl: IntlShape): string {
  switch (method) {
    case InjectionMethod.Implant:
      return intl.formatMessage({ id: 'injectionMethod.implant', defaultMessage: 'Имплант' })
    case InjectionMethod.Inhalation:
      return intl.formatMessage({ id: 'injectionMethod.inhalation', defaultMessage: 'Ингаляционно' })
    case InjectionMethod.Instillation:
      return intl.formatMessage({ id: 'injectionMethod.instillation', defaultMessage: 'Инстилляция' })
    case InjectionMethod.Nasal:
      return intl.formatMessage({ id: 'injectionMethod.nasal', defaultMessage: 'Назально' })
    case InjectionMethod.Oral:
      return intl.formatMessage({ id: 'injectionMethod.oral', defaultMessage: 'Перорально' })
    case InjectionMethod.Parenteral:
      return intl.formatMessage({ id: 'injectionMethod.parenteral', defaultMessage: 'Парентерально' })
    case InjectionMethod.Rectal:
      return intl.formatMessage({ id: 'injectionMethod.rectal', defaultMessage: 'Ректально' })
    case InjectionMethod.Sublingual:
      return intl.formatMessage({ id: 'injectionMethod.sublingual', defaultMessage: 'Сублингвально' })
    case InjectionMethod.Transdermal:
      return intl.formatMessage({ id: 'injectionMethod.transdermal', defaultMessage: 'Трансдермально' })
    case InjectionMethod.Vaginal:
      return intl.formatMessage({ id: 'injectionMethod.vaginal', defaultMessage: 'Интравагинально' })
    case InjectionMethod.Combined:
      return intl.formatMessage({ id: 'injectionMethod.combined', defaultMessage: 'Все методы введения' })
  }
}

export function getInjectionMethodNameFromString (method: string, intl: IntlShape): string {
  switch (method.toLowerCase().trim()) {
    case 'implant':
    case 'имплантат':
    case 'имплант':
    case 'импл':
      return intl.formatMessage({ id: 'injectionMethod.implant', defaultMessage: 'Имплант' })
    case 'inhalation':
    case 'inhal':
    case 'ингаляционно':
    case 'ингал':
      return intl.formatMessage({ id: 'injectionMethod.inhalation', defaultMessage: 'Ингаляционно' })
    case 'instillation':
    case 'instill':
    case 'instil':
    case 'инстилляция':
    case 'инстиляция':
    case 'инстилл':
    case 'инстил':
      return intl.formatMessage({ id: 'injectionMethod.instillation', defaultMessage: 'Инстилляция' })
    case 'nasal':
    case 'назально':
    case 'назал':
    case 'n':
    case 'н':
      return intl.formatMessage({ id: 'injectionMethod.nasal', defaultMessage: 'Назально' })
    case 'oral':
    case 'орально':
    case 'орал':
    case 'o': // english o
    case 'о': // russian o
    case 'ор': // russian oр
      return intl.formatMessage({ id: 'injectionMethod.oral', defaultMessage: 'Перорально' })
    case 'parenteral':
    case 'парентерально':
    case 'парент':
    case 'пар':
    case 'парен':
    case 'парентерал':
    case 'парентераль':
    case 'парентеральн':
    case 'п':
    case 'p': // english p
    case 'parent':
      return intl.formatMessage({ id: 'injectionMethod.parenteral', defaultMessage: 'Парентерально' })
    case 'rectal':
    case 'ректально':
    case 'рект':
    case 'ректал':
    case 'ректаль':
    case 'ректальн':
    case 'р': // russian р
    case 'r':
      return intl.formatMessage({ id: 'injectionMethod.rectal', defaultMessage: 'Ректально' })
    case 'sublingual':
    case 'сублингвально':
    case 'сублинг':
    case 'субл':
    case 'сл':
    case 'sl':
      return intl.formatMessage({ id: 'injectionMethod.sublingual', defaultMessage: 'Сублингвально' })
    case 'transdermal':
    case 'трансдермально':
    case 'трансдерм':
    case 'трансдермаль':
    case 'трансдермальн':
    case 'тд':
    case 'td':
      return intl.formatMessage({ id: 'injectionMethod.transdermal', defaultMessage: 'Трансдермально' })
    case 'vaginal':
    case 'вагинально':
    case 'вагин':
    case 'вагина':
    case 'v':
    case 'в':
      return intl.formatMessage({ id: 'injectionMethod.vaginal', defaultMessage: 'Интравагинально' })
  }
  return ''
}
